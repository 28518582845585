import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllInventoryAsync,
  deleteInventorysync,
  createInventoryAsync,
  getInventoryByIdAsync,
  updateInventoryAsync,
  getInventoryWithoutPaginationAsync,
  getInventoryChemicalAsync,
  getAllInventoryWithOutPaginateAsync,
  getAllInventoryWithChemicalAsync,
  getAllInventoryJobsAsync
} from "./inventory.async";
import Pagination from "theme/overrides/Pagination";

const initialState = {
  inventoryListLoader: false,
  inventoryList: [],
  inventoryInfo: {},
  inventoryjobByCustomer: [],

  inventoryListWithOutPaginateLoader: false,
  inventoryListWithChemical: [],
  inventoryInfoWithChemical: {},

  createInventoryLoader: false,
  createInventoryInfo: {},

  deleteInventoryLoading: false,
  inventoryDeleteInfo: {},

  inventoryByIdLoading: false,
  inventoryById: {},
  inventoryByIdInfo: {},

  updateInventoryLoader: false,
  updateInventoryInfo: {},

  inventoryWithoutPaginationLoader: false,
  inventoryListWithoutPagination: [],
  inventoryListWithoutPaginationInfo: {},

  inventoryChemicalListLoader: false,
  inventoryChemicalList: [],
  inventoryChemicalListInfo: {},

  inventoryListWithOutpaginateLoader: false,
  inventoryListWithOutpaginate: [],
  inventoryListWithOutpaginateInfo: {}
};

export const InventorySlice = createSlice({
  name: "inventory",
  initialState,
  extraReducers: (builder) => {
    //Inventory List
    builder.addMatcher(isAnyOf(getAllInventoryAsync.pending), (state) => {
      state.inventoryListLoader = true;
      state.inventoryListInfo = {};
      state.inventoryList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllInventoryAsync.fulfilled),
      (state, action) => {
        state.inventoryListLoader = false;
        state.inventoryListInfo = {};
        state.inventoryList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInventoryAsync.rejected),
      (state, action) => {
        state.inventoryListLoader = false;
        state.inventoryListInfo = {};
        state.inventoryList = [];
      }
    );

    // Create Inventory
    builder.addMatcher(isAnyOf(createInventoryAsync.pending), (state) => {
      state.createInventoryLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createInventoryAsync.fulfilled),
      (state, action) => {
        state.createInventoryLoader = false;
        state.createInventoryInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createInventoryAsync.rejected),
      (state, action) => {
        state.createInventoryLoader = false;
      }
    );

    //Inventory Delete
    builder.addMatcher(isAnyOf(deleteInventorysync.pending), (state) => {
      state.deleteInventoryLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteInventorysync.fulfilled),
      (state, action) => {
        state.deleteInventoryLoading = false;
        state.inventoryDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteInventorysync.rejected),
      (state, action) => {
        state.deleteInventoryLoading = false;
      }
    );

    //Inventory By Id
    builder.addMatcher(isAnyOf(getInventoryByIdAsync.pending), (state) => {
      state.inventoryByIdLoading = true;
      state.inventoryByIdInfo = {};
      state.inventoryById = {};
    });
    builder.addMatcher(
      isAnyOf(getInventoryByIdAsync.fulfilled),
      (state, action) => {
        state.inventoryByIdLoading = false;
        state.inventoryByIdInfo = {};
        state.inventoryById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryByIdAsync.rejected),
      (state, action) => {
        state.inventoryByIdLoading = false;
        state.inventoryByIdInfo = {};
        state.inventoryById = {};
      }
    );

    //Inventory Update
    builder.addMatcher(isAnyOf(updateInventoryAsync.pending), (state) => {
      state.updateInventoryLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateInventoryAsync.fulfilled),
      (state, action) => {
        state.updateInventoryLoader = false;
        state.updateInventoryInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInventoryAsync.rejected),
      (state, action) => {
        state.updateInventoryLoader = false;
      }
    );

    // get allInventory without pagination
    builder.addMatcher(
      isAnyOf(getInventoryWithoutPaginationAsync.pending),
      (state) => {
        state.inventoryWithoutPaginationLoader = true;
        state.inventoryListWithoutPaginationInfo = {};
        state.inventoryListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.inventoryWithoutPaginationLoader = false;
        state.inventoryListWithoutPaginationInfo = {};
        state.inventoryListWithoutPagination = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryWithoutPaginationAsync.rejected),
      (state, action) => {
        state.inventoryWithoutPaginationLoader = false;
        state.inventoryListWithoutPaginationInfo = {};
        state.inventoryListWithoutPagination = [];
      }
    );

    //Inventory Chemical List
    builder.addMatcher(isAnyOf(getInventoryChemicalAsync.pending), (state) => {
      state.inventoryChemicalListLoader = true;
      state.inventoryChemicalListInfo = {};
      state.inventoryChemicalList = [];
    });
    builder.addMatcher(
      isAnyOf(getInventoryChemicalAsync.fulfilled),
      (state, action) => {
        state.inventoryChemicalListLoader = false;
        state.inventoryChemicalListInfo = {};
        state.inventoryChemicalList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryChemicalAsync.rejected),
      (state, action) => {
        state.inventoryChemicalListLoader = false;
        state.inventoryChemicalListInfo = {};
        state.inventoryChemicalList = [];
      }
    );

    // get all inventory with out Pagination
    builder.addMatcher(
      isAnyOf(getAllInventoryWithOutPaginateAsync.pending),
      (state) => {
        state.inventoryListWithOutpaginateLoader = true;
        state.inventoryListInfo = {};
        state.inventoryList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInventoryWithOutPaginateAsync.fulfilled),
      (state, action) => {
        state.inventoryListWithOutpaginateLoader = false;
        state.inventoryListWithOutpaginateInfo = {};
        state.inventoryListWithOutpaginate = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInventoryWithOutPaginateAsync.rejected),
      (state, action) => {
        state.inventoryListWithOutpaginateLoader = false;
        state.inventoryListWithOutpaginateInfo = {};
        state.inventoryListWithOutpaginate = [];
      }
    );
    //Inventory list With Chemical
        //Inventory List
        builder.addMatcher(isAnyOf(getAllInventoryWithChemicalAsync.pending), (state) => {
          state.inventoryListWithOutPaginateLoader = true;
          state.inventoryInfoWithChemical = {};
          state.inventoryListWithChemical = [];
        });
        builder.addMatcher(
          isAnyOf(getAllInventoryWithChemicalAsync.fulfilled),
          (state, action) => {
            state.inventoryListWithOutPaginateLoader = false;
            state.inventoryInfoWithChemical = {};
            state.inventoryListWithChemical = action.payload?.data || [];
          }
        );
        builder.addMatcher(
          isAnyOf(getAllInventoryWithChemicalAsync.rejected),
          (state, action) => {
            state.inventoryListWithOutPaginateLoader = false;
            state.inventoryInfoWithChemical = {};
            state.inventoryListWithChemical = [];
          }
        );

        // JOBS ON CUSTOMER
        builder.addMatcher(isAnyOf(getAllInventoryJobsAsync.pending), (state) => {
          state.inventoryListWithChemical = [];
        });
        builder.addMatcher(
          isAnyOf(getAllInventoryJobsAsync.fulfilled),
          (state, action) => {
            state.inventoryjobByCustomer = action.payload?.data || [];
          }
        );
        builder.addMatcher(
          isAnyOf(getAllInventoryJobsAsync.rejected),
          (state, action) => {
            state.inventoryjobByCustomer = [];
          }
        );
  },
  reducers: {
    emptyInventory: () => initialState
  }
});
export const { emptyInventory } = InventorySlice.actions;

export default InventorySlice.reducer;
