import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getVesselAsync = createAsyncThunk(
  "master/getVesselAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-vessel?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createVesselAsync = createAsyncThunk(
  "master/createVesselAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-vessel`, payload, toolkit);
  }
);

export const updateVesselAsync = createAsyncThunk(
  "admin/updateVesselAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-vessel/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const getVesselByIdAsync = createAsyncThunk(
  "master/getVesselByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-vessel/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteVesselAsync = createAsyncThunk(
  "master/deleteVesselAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-vessel/${payload}`,
      [],
      toolkit
    );
  }
);
