import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllServiceAsync,
  createServiceAsync,
  getAllActivityAsync,
  getServiceByIdAsync,
  updateServiceAsync,
  deleteServiceAsync,
  getAllInventoryAsync,
  getAllServicewithoutpaginationAsync,
} from "./service.async";

const initialState = {
  serviceLoader: false,
  InventoryLoader: false,
  serviceList: [],
  getAllActivity: [],
  createService: [],
  getServiceById: [],
  updateService: [],
  deleteService: [],
  getAllInventory: [],
  servicewithoutpaginationList:[],
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllServiceAsync.pending,

        getAllActivityAsync.pending,
        createServiceAsync.pending,
        getServiceByIdAsync.pending,
        updateServiceAsync.pending,
        deleteServiceAsync.pending,
        getAllServicewithoutpaginationAsync.pending,
      ),
      (state) => {
        state.serviceLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllServicewithoutpaginationAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.servicewithoutpaginationList = action.payload?.data;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllServiceAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.serviceList = action.payload?.data;
      }
    );

    builder.addMatcher(
      isAnyOf(createServiceAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.createService = action?.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceByIdAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.getServiceById = action?.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateServiceAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.updateService = action?.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteServiceAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.deleteService = action?.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllActivityAsync.fulfilled),
      (state, action) => {
        state.serviceLoader = false;
        state.getAllActivity = action?.payload?.data;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllServiceAsync.rejected,

        createServiceAsync.rejected,
        getServiceByIdAsync.rejected,
        updateServiceAsync.rejected,
        deleteServiceAsync.rejected,
        getAllServicewithoutpaginationAsync.rejected,
      ),
      (state, action) => {
        state.serviceLoader = false;
      }
    );

    // for Inventory
    builder.addMatcher(isAnyOf(getAllInventoryAsync.pending), (state) => {
      state.InventoryLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllInventoryAsync.fulfilled),
      (state, action) => {
        state.InventoryLoader = false;
        state.getAllInventory = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllInventoryAsync.rejected),
      (state, action) => {
        state.InventoryLoader = false;
      }
    );
  },
  reducers: {
    emptyService: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyService } = serviceSlice.actions;
export default serviceSlice.reducer;
