import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getContractConvertChartDataAsync } from "./contractConversion.async";

const initialState = {
  contractConvertChartDataLoader: false,
  contractConvertChartDataInfo: {}
};

export const contractConversionSlice = createSlice({
  name: "contractConversion",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getContractConvertChartDataAsync.pending),
      (state) => {
        state.leadConvertChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getContractConvertChartDataAsync.fulfilled),
      (state, action) => {
        state.contractConvertChartDataLoader = false;
        state.contractConvertChartDataInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getContractConvertChartDataAsync.rejected),
      (state, action) => {
        state.contractConvertChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptyContract: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyContract } = contractConversionSlice.actions;
export default contractConversionSlice.reducer;
