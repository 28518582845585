import { IconButton, Typography,Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import Iconify from "components/iconify/Iconify";
import * as yup from "yup";
import { capitalize } from "lodash";
import CopyButton from "components/Custom-Text-Copied/CopyButton";


export const countryColumnHandler = ({ handleOpenPopover, pageNumber }) => {
  const columns = [
    {
      name: "Action",
      selector: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenPopover(e, row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        );
      },
      width: "80px"
    },
    {
      name: "Sl No.",
      selector: (row, index) =>
        pageNumber === 1
          ? index + 1
          : index === 9
          ? `${pageNumber}0`
          : `${pageNumber - 1}${index + 1}`,
      width: "80px"
    },

    {
      name: "Country Name",
      selector: (row) => {
        const countryName = capitalize(row?.countryName ? row?.countryName : "N/A");
        return (
          <Box sx={{ cursor: "pointer" }}>
          <Tooltip
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {countryName}
                <CopyButton textToCopy={countryName} />
              </Box>
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {countryName}
            </Typography>
          </Tooltip>
        </Box>
        );
      }
    },
    {
      name: "Phone Code",
      selector: (row) => {
        return (
          <Tooltip title={row?.phoneCode ? row?.phoneCode : "N/A"}>
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {row?.phoneCode ? row.phoneCode : "N/A"}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      name: "Currency Code",
      selector: (row) => {
        return (
          <Tooltip
            title={
              row?.currencyCode?.currencyCode
                ? row?.currencyCode?.currencyCode
                : "N/A"
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {row?.currencyCode?.currencyCode
                ? row?.currencyCode?.currencyCode
                : "N/A"}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      name: "Currency Symbol",
      selector: (row) => {
        return (
          <Tooltip
            title={
              row?.currencyCode?.currencySymbol
                ? row?.currencyCode?.currencySymbol
                : "N/A"
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {row?.currencyCode?.currencySymbol
                ? row?.currencyCode?.currencySymbol
                : "N/A"}
            </Typography>
          </Tooltip>
        );
      }
    },
  ];

  return columns;
};

export const _initial = {
  countryName: "",
  phoneCode: "",
  currencyCode: {}
};

export const _validate = yup.object().shape({
  countryName: yup.string().required(),
  phoneCode: yup.string().required(),

  currencyCode: yup.object({
    label: yup.string().required()
  })
});
