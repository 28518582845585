import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getAllInvoiceAsync = createAsyncThunk(
  "master/getAllInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-invoice-table2?page=${payload?.page || 1}&limit=${payload?.limit || 10
      }&search=${payload?.search || ""}&client=${payload.client || ""}&status=${payload.status || ""}&site=${payload.site || ""}&eDate=${payload.eDate || ""}&sDate=${payload.sDate || ""}&invoiceNumber=${payload.invoiceNumber || ""}&lob=${payload.lob || ""}&jobNum=${payload.jobNum || ""}
      `,
      [],
      toolkit
    );
  }
);

//create Invoice
export const createInvoiceAsync = createAsyncThunk(
  "createInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/create-invoice`,
      payload,
      toolkit
    );
  }
);
//create Invoice
export const getTotalAmount = createAsyncThunk(
  "getTotalAmount",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/get-totalAmount-fromJob`,
      payload,
      toolkit
    );
  }
);

//update Invoice Status
export const updateInvoiceStatusAsync = createAsyncThunk(
  "admin/updateInvoiceStatusAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/invoice/update-invoice-status/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

//getByID Invoice
export const getInvoiceByIdAsync = createAsyncThunk(
  "master/getInvoiceById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-invoice/${payload}`,
      [],
      toolkit
    );
  }
);

//update Invoice
export const updateInvoiceAsync = createAsyncThunk(
  "admin/updateInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/invoice/update-invoice/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//invoicedate-update
export const invoicedateUpdateAsync = createAsyncThunk(
  "admin/invoicedateUpdate",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/invoice/invoicedate-update/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//delete Invoice
export const deleteInvoiceAsync = createAsyncThunk(
  "master/deleteInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/invoice/delete-invoice/${payload}`,
      [],
      toolkit
    );
  }
);



// list-quotation-job-dropdown

export const getQuotationAndJobByCustomerIdAsync = createAsyncThunk(
  "invoice/getQuotationAndJobByCustomerId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-quotation-job-dropdown/${payload}`,
      [],
      toolkit
    );
  }
);

// getBankAccountsByIdAsync
export const getBankAccountsByIdAsync = createAsyncThunk(
  "invoice/getBankAccountsById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-bank-accounts/${payload}`,
      [],
      toolkit
    );
  }
);

// getAllReceiptsByIdAsync 
export const getAllReceiptsByIdAsync = createAsyncThunk(
  "invoice/get-all-receipts",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-all-receipts/${payload}`,
      [],
      toolkit
    );
  }
);

// getAllReceiptsByIdAsync 
export const deleteReceiptsByIdAsync = createAsyncThunk(
  "invoice/delete-receipts",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/invoice/delete-receipt/${payload}`,
      [],
      toolkit
    );
  }
);

// getReceiptsByIdAsync
export const getReceiptsByIdAsync = createAsyncThunk( 
  "invoice/getReceiptsByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-receipt/${payload}`,
      [],
      toolkit
    );
  }
);

// updateReceiptsByIdAsync
export const updateReceiptAsync = createAsyncThunk(
  "admin/updateReceiptsByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/invoice/update-receipt/${payload.id}`,
      payload,
      toolkit
    );
  }
);


// get-document-html
export const getInvoiceDocumentHtmlByDocumentIdAsync = createAsyncThunk(
  "invoice/getContractDocumentHtmlByDocumentId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-document-html/${payload?.documentId}/${payload?.invoiceId}`,
      [],
      toolkit
    );
  }
);

// Get Contract Document Dropdown 

export const getInvoiceDocumentDropdownAsync = createAsyncThunk(
  "contract/getInvoiceDocumentDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-document-dropdown/`,
      [],
      toolkit
    );
  }
);

//download Invoice Csv File
export const invoiceExcelDownloadAsync = ({ page, limit, search, client, status, site, eDate, sDate, invoiceNumber, lob, jobNum}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/invoice/invoice-export-to-csv?token=${isJson(localStorage.getItem("auth"))
      ? JSON.parse(localStorage.getItem("auth"))?.token
      : null
    }&page=${page || 1}&limit=${limit || 10
    }&search=${search || ""}&client=${client || ""}&status=${status || ""}&site=${site || ""}&eDate=${eDate || ""}&sDate=${sDate || ""}&invoiceNumber=${invoiceNumber || ""}&lob=${lob || ""}&jobNum=${jobNum || ""}`,
    "_parent"
  );
};


//  Invoice Send Email 
export const invoiceEmailPdfAsync = createAsyncThunk(
  "invoiceEmailPdf",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/invoice/save-pdf-and-mail/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// getBankAccountsByIdAsync
export const getBankAccountsBySiteIdAsync = createAsyncThunk(
  "invoice/getBankAccountsBySiteId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-banks/${payload}`,
      [],
      toolkit
    );
  }
);

