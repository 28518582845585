import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllPurchaseOrderAsync,
  getAllPurchaseOrderWithoutPaginationAsync,
  deletePurchaseOrderAsync,
  createPurchaseOrderAsync,
  getPurchaseOrderByIdAsync,
  updatePurchaseOrderAsync,
  getItemSkuDropdownAsync,
  getVariantByIdAsync,
  getVendorSkuByIdAsync,
  getVendorlocationByIdAsync
} from "./purchaseOrder.async";

const initialState = {
  purchaseOrderListLoader: false,
  purchaseOrderList: [],
  purchaseOrderListInfo: {},

  purchaseOrderListWithoutPaginationLoader: false,
  purchaseOrderListWithoutPagination: [],
  purchaseOrderListWithoutPaginationInfo: {},

  purchaseOrderDeleteLoading: false,
  purchaseOrderDeleteInfo: {},

  purchaseOrderCreateLoader: false,
  purchaseOrderCreateInfo: {},

  purchaseOrderByIdLoading: false,
  purchaseOrderById: {},
  purchaseOrderByIdInfo: {},

  purchaseOrderUpdateLoader: false,
  purchaseOrderUpdateInfo: {},

  itemSkuDropdownListLoader: false,
  itemSkuDropdownList: [],
  itemSkuDropdownListInfo: {},

  variantListByIdLoader: false,
  variantListByIdInfo: {},
  variantListById: [],

  vendorSkuListByIdLoader: false,
  vendorSkuListByIdInfo: {},
  vendorSkuListById: [],

  vendorLocationLoader:false,
  vendorLocationData :[],
};

export const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  extraReducers: (builder) => {
    // Purchase Order List
    builder.addMatcher(isAnyOf(getAllPurchaseOrderAsync.pending), (state) => {
      state.purchaseOrderListLoader = true;
      state.purchaseOrderListInfo = {};
      state.purchaseOrderList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllPurchaseOrderAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderListLoader = false;
        state.purchaseOrderListInfo = {};
        state.purchaseOrderList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPurchaseOrderAsync.rejected),
      (state, action) => {
        state.purchaseOrderListLoader = false;
        state.purchaseOrderListInfo = {};
        state.purchaseOrderList = [];
      }
    );
    // Purchase Order List Without pagination
    builder.addMatcher(
      isAnyOf(getAllPurchaseOrderWithoutPaginationAsync.pending),
      (state) => {
        state.purchaseOrderListWithoutPaginationLoader = true;
        state.purchaseOrderListWithoutPaginationInfo = {};
        state.purchaseOrderListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPurchaseOrderWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderListWithoutPaginationLoader = false;
        state.purchaseOrderListWithoutPaginationInfo = {};
        state.purchaseOrderListWithoutPagination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPurchaseOrderWithoutPaginationAsync.rejected),
      (state, action) => {
        state.purchaseOrderListWithoutPaginationLoader = false;
        state.purchaseOrderListWithoutPaginationInfo = {};
        state.purchaseOrderListWithoutPagination = [];
      }
    );

    // Purchase Order Delete
    builder.addMatcher(isAnyOf(deletePurchaseOrderAsync.pending), (state) => {
      state.purchaseOrderDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deletePurchaseOrderAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderDeleteLoading = false;
        state.purchaseOrderDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deletePurchaseOrderAsync.rejected),
      (state, action) => {
        state.purchaseOrderDeleteLoading = false;
      }
    );

    // Create purchase order
    builder.addMatcher(isAnyOf(createPurchaseOrderAsync.pending), (state) => {
      state.purchaseOrderCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createPurchaseOrderAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderCreateLoader = false;
        state.purchaseOrderCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createPurchaseOrderAsync.rejected),
      (state, action) => {
        state.purchaseOrderCreateLoader = false;
      }
    );

    //purchase order By Id
    builder.addMatcher(isAnyOf(getPurchaseOrderByIdAsync.pending), (state) => {
      state.purchaseOrderByIdLoading = true;
      state.purchaseOrderByIdInfo = {};
      state.purchaseOrderById = {};
    });
    builder.addMatcher(
      isAnyOf(getPurchaseOrderByIdAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderByIdLoading = false;
        state.purchaseOrderByIdInfo = {};
        state.purchaseOrderById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getPurchaseOrderByIdAsync.rejected),
      (state, action) => {
        state.purchaseOrderByIdLoading = false;
        state.purchaseOrderByIdInfo = {};
        state.purchaseOrderById = {};
      }
    );

    // Update purchase order
    builder.addMatcher(isAnyOf(updatePurchaseOrderAsync.pending), (state) => {
      state.purchaseOrderUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updatePurchaseOrderAsync.fulfilled),
      (state, action) => {
        state.purchaseOrderUpdateLoader = false;
        state.purchaseOrderUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updatePurchaseOrderAsync.rejected),
      (state, action) => {
        state.purchaseOrderUpdateLoader = false;
      }
    );

    // Item Sku Dropdown List
    builder.addMatcher(isAnyOf(getItemSkuDropdownAsync.pending), (state) => {
      state.itemSkuDropdownListLoader = true;
      state.itemSkuDropdownListInfo = {};
      state.itemSkuDropdownList = [];
    });
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.fulfilled),
      (state, action) => {
        state.itemSkuDropdownListLoader = false;
        state.itemSkuDropdownListInfo = {};
        state.itemSkuDropdownList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.rejected),
      (state, action) => {
        state.itemSkuDropdownListLoader = false;
        state.itemSkuDropdownListInfo = {};
        state.itemSkuDropdownList = [];
      }
    );

    // Get Variant By Id
    builder.addMatcher(isAnyOf(getVariantByIdAsync.pending), (state) => {
      state.variantListByIdLoader = true;
      state.variantListByIdInfo = {};
      state.variantListById = [];
    });
    builder.addMatcher(
      isAnyOf(getVariantByIdAsync.fulfilled),
      (state, action) => {
        state.variantListByIdLoader = false;
        state.variantListByIdInfo = {};
        state.variantListById = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getVariantByIdAsync.rejected),
      (state, action) => {
        state.variantListByIdLoader = false;
        state.variantListByIdInfo = {};
        state.variantListById = [];
      }
    );

    // Get VendorSku By Id
    builder.addMatcher(isAnyOf(getVendorSkuByIdAsync.pending), (state) => {
      state.vendorSkuListByIdLoader = true;
      state.vendorSkuListByIdInfo = {};
      state.vendorSkuListById = [];
    });
    builder.addMatcher(
      isAnyOf(getVendorSkuByIdAsync.fulfilled),
      (state, action) => {
        state.vendorSkuListByIdLoader = false;
        state.vendorSkuListByIdInfo = {};
        state.vendorSkuListById = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getVendorSkuByIdAsync.rejected),
      (state, action) => {
        state.vendorSkuListByIdLoader = false;
        state.vendorSkuListByIdInfo = {};
        state.vendorSkuListById = [];
      }
    );


        // Get VendorSku By Id
        builder.addMatcher(isAnyOf(getVendorlocationByIdAsync.pending), (state) => {
          state.vendorLocationLoader = true;
        });
        builder.addMatcher(
          isAnyOf(getVendorlocationByIdAsync.fulfilled),
          (state, action) => {
            state.vendorLocationLoader = false;
            state.vendorLocationData = action.payload?.data || [];
          }
        );
        builder.addMatcher(
          isAnyOf(getVendorlocationByIdAsync.rejected),
          (state, action) => {
            state.vendorLocationLoader = false;
            state.vendorLocationData = [];
          }
        );
  },
  reducers: {
    emptyPurchaseOrder: () => initialState,
  },
});
export const { emptyPurchaseOrder } = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
