import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    getAllNoMenuListAsync,
} from "./InventoryNoMenu.async";

const initialState = {
  getAllNoMenuListLoader: false,
  getAllNoMenuList: [],
  getAllNoMenuListInfo: {}
};

export const noMenuInventorySlice = createSlice({
  name: "state",
  initialState,
  extraReducers: (builder) => {


    //State List
    builder.addMatcher(isAnyOf(getAllNoMenuListAsync.pending), (state) => {
      state.getAllNoMenuListLoader = true;
      state.getAllNoMenuListInfo = {};
      state.getAllNoMenuList = [];
      state.stateListLoader = true;
    });
    builder.addMatcher(isAnyOf(getAllNoMenuListAsync.fulfilled), (state, action) => {
      state.getAllNoMenuListLoader = false;
      state.getAllNoMenuListInfo = {};
      state.getAllNoMenuList = action.payload || [];
    });
    builder.addMatcher(isAnyOf(getAllNoMenuListAsync.rejected), (state, action) => {
      state.getAllNoMenuListLoader = false;
      state.getAllNoMenuListInfo = {};
      state.getAllNoMenuList = [];
    });
  },
  reducers: {
    emptyState: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyNoMenuInventory } = noMenuInventorySlice.actions;
export default noMenuInventorySlice.reducer;
