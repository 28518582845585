import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getleadConvertChartDataAsync } from "./leadConversion.async";

const initialState = {
  leadConvertChartDataLoader: false,
  leadConvertChartDataInfo: {}
};

export const leadConversionSlice = createSlice({
  name: "leadConversion",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getleadConvertChartDataAsync.pending),
      (state) => {
        state.leadConvertChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getleadConvertChartDataAsync.fulfilled),
      (state, action) => {
        state.leadConvertChartDataLoader = false;
        state.leadConvertChartDataInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getleadConvertChartDataAsync.rejected),
      (state, action) => {
        state.leadConvertChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptyMis: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyMis } = leadConversionSlice.actions;
export default leadConversionSlice.reducer;
