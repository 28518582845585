import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { getAllAttendanceAsync, getAttendanceDetailAsync } from "./attendance.async";

const initialState = {
  attendanceListLoader: false,
  attendanceListInfo: {},
  attendanceList: {},

  attendanceDetailListLoader: false,
  attendanceDetailListInfo: {},
  attendanceDetailList: {},
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,

  extraReducers: (builder) => {
    // Attendance List
    builder.addMatcher(isAnyOf(getAllAttendanceAsync.pending), (state) => {
      state.attendanceListLoader = true;
      state.attendanceListInfo = {};
      state.attendanceList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllAttendanceAsync.fulfilled),
      (state, action) => {
        state.attendanceListLoader = false;
        state.attendanceListInfo = {};
        state.attendanceList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAttendanceAsync.rejected),
      (state, action) => {
        state.attendanceListLoader = false;
        state.attendanceListInfo = {};
        state.attendanceList = [];
      }
    );

    // Details Attendance List
    builder.addMatcher(isAnyOf(getAttendanceDetailAsync.pending), (state) => {
      state.attendanceDetailListLoader = true;
      state.attendanceDetailListInfo = {};
      state.attendanceDetailList = [];
    });
    builder.addMatcher(
      isAnyOf(getAttendanceDetailAsync.fulfilled),
      (state, action) => {
        state.attendanceDetailListLoader = false;
        state.attendanceDetailListInfo = {};
        state.attendanceDetailList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAttendanceDetailAsync.rejected),
      (state, action) => {
        state.attendanceDetailListLoader = false;
        state.attendanceDetailListInfo = {};
        state.attendanceDetailList = [];
      }
    );
  },
});

export const { emptyAttendance } = attendanceSlice.actions;

export default attendanceSlice.reducer;
