import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllPortAsync,
  createPortAsync,
  getPortByIdAsync,
  updatePortAsync,
  deletePortAsync,
  getPortByCountryIdAsync,
  getAllPortWithOutPaginationAsync,
  getMultiplePortByMultipleCountryIdAsync
} from "./port.async";

const initialState = {
  portCreateLoader: false,
  portCreateInfo: {},

  getMultiplePortByMultipleCountryIdLoader: false,
  multiplePortByMultipleCountryIdCreateInfo: {},

  portListLoader: false,
  portList: [],
  portListInfo: {},

  portDeleteLoading: false,
  portDeleteInfo: {},

  portByIdLoading: false,
  portById: {},
  portByIdInfo: {},

  portUpdateLoader: false,
  portUpdateInfo: {},

  portByCountryLoader: false,
  getPortByCountryId: {},
  getportByCountryIdInfo: {},

  portListWithOutPaginationLoader: false,
  portListWithOutPagination: [],
  portListInfoWithOutPagination: {},

};

export const portSlice = createSlice({
  name: "port",
  initialState,
  extraReducers: (builder) => {
    // Create Port
    builder.addMatcher(isAnyOf(createPortAsync.pending), (state) => {
      state.portCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createPortAsync.fulfilled), (state, action) => {
      state.portCreateLoader = false;
      state.portCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createPortAsync.rejected), (state, action) => {
      state.portCreateLoader = false;
    });

    //Port List
    builder.addMatcher(isAnyOf(getAllPortAsync.pending), (state) => {
      state.portListLoader = true;
      state.portListInfo = {};
      state.portList = [];
      state.portListLoader=true
    });
    builder.addMatcher(isAnyOf(getAllPortAsync.fulfilled), (state, action) => {
      state.portListLoader = false;
      state.portListInfo = {};
      state.portList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllPortAsync.rejected), (state, action) => {
      state.portListLoader = false;
      state.portListInfo = {};
      state.portList = [];
    });

    //Port Delete
    builder.addMatcher(isAnyOf(deletePortAsync.pending), (state) => {
      state.portDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deletePortAsync.fulfilled), (state, action) => {
      state.portDeleteLoading = false;
      state.portDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deletePortAsync.rejected), (state, action) => {
      state.portDeleteLoading = false;
    });

    //Port By Id
    builder.addMatcher(isAnyOf(getPortByIdAsync.pending), (state) => {
      state.portByIdLoading = true;
      state.portByIdInfo = {};
      state.portById = {};
    });
    builder.addMatcher(
      isAnyOf(getPortByIdAsync.fulfilled),
      (state, action) => {
        state.portByIdLoading = false;
        state.portByIdInfo = {};
        state.portById = action.payload?.data;
      }
    );
    builder.addMatcher(isAnyOf(getPortByIdAsync.rejected), (state, action) => {
      state.portByIdLoading = false;
      state.portByIdInfo = {};
      state.portById = {};
    });

    //Port Update
    builder.addMatcher(isAnyOf(updatePortAsync.pending), (state) => {
      state.portUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updatePortAsync.fulfilled), (state, action) => {
      state.portUpdateLoader = false;
      state.portUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updatePortAsync.rejected), (state, action) => {
      state.portUpdateLoader = false;
    });

    // get Port By Country  Id
    builder.addMatcher(isAnyOf(getPortByCountryIdAsync.pending), (state) => {
      state.portByCountryLoader = true;
      state.getPortByCountryIdInfo = {};
      state.getPortByCountryId = {};
    });
    builder.addMatcher(
      isAnyOf(getPortByCountryIdAsync.fulfilled),
      (state, action) => {
        state.portByCountryLoader = false;
        state.getPortByCountryIdInfo = {};
        state.getPortByCountryId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getPortByCountryIdAsync.rejected),
      (state, action) => {
        state.portByCountryLoader = false;
        state.getPortByCountryIdInfo = {};
        state.getPortByCountryId = {};
      }
    );
    
    //Port List WithOut Pagination 
    builder.addMatcher(isAnyOf(getAllPortWithOutPaginationAsync.pending), (state) => {
      state.portListWithOutPaginationLoader = true;
      state.portListInfoWithOutPagination = {};
      state.portListWithOutPagination = [];
    });
    builder.addMatcher(isAnyOf(getAllPortWithOutPaginationAsync.fulfilled), (state, action) => {
      state.portListWithOutPaginationLoader = false;
      state.portListInfoWithOutPagination = {};
      state.portListWithOutPagination = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllPortWithOutPaginationAsync.rejected), (state, action) => {
      state.portListWithOutPaginationLoader = false;
      state.portListInfoWithOutPagination = {};
      state.portList = [];
    });

       // Create Port
       builder.addMatcher(isAnyOf(getMultiplePortByMultipleCountryIdAsync.pending), (state) => {
        state.portCreateLoader = true;
      });
      builder.addMatcher(isAnyOf(getMultiplePortByMultipleCountryIdAsync.fulfilled), (state, action) => {
        state.getMultiplePortByMultipleCountryIdLoader = false;
        state.multiplePortByMultipleCountryIdCreateInfo = action.payload;
      });
      builder.addMatcher(isAnyOf(getMultiplePortByMultipleCountryIdAsync.rejected), (state, action) => {
        state.getMultiplePortByMultipleCountryIdLoader = false;
      });
  },
  reducers: {
    emptyPort: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyPort } = portSlice.actions;
export default portSlice.reducer;
