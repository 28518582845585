import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllFollowUpAsync = createAsyncThunk(
  "master/getAllFollowUp",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/list-followup?leadId=${payload?.leadId}&page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createFollowUpAsync = createAsyncThunk(
    "master/createFollowUp",
    async (payload, toolkit) => {
      return await AxiosClient(
        "POST",
        `/lead/create-followup`,
        payload,
        toolkit
      );
    }
  );