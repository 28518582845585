import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllNotesAsync = createAsyncThunk(
  "master/getAllNotes",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/lead/list-note?leadId=${payload?.leadId}&page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }`,
      [],
      toolkit
    );
  }
);

export const createNotesAsync = createAsyncThunk(
    "master/createNotes",
    async (payload, toolkit) => {
      return await AxiosClient(
        "POST",
        `/lead/create-note`,
        payload,
        toolkit
      );
    }
  );