import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createLOBAsync = createAsyncThunk(
  "master/lob",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-lob`, payload, toolkit);
  });

  // getAllLOBwithoutpaginationAsync

  export const getAllLOBwithoutpaginationAsync = createAsyncThunk(
    "master/getAllLobwithoutpagination",
    async (payload, toolkit) => {
      return await AxiosClient("GET", `/master/list-lob`, [], toolkit);
    });


// getAllLOBAsync
export const getAllLOBAsync = createAsyncThunk(
  "master/getAllLob",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-lob?page=${payload?.page || 1}&limit=${payload?.limit || 10}&search=${payload?.search || ""}`, [], toolkit);
  });

export const deleteLOBAsync = createAsyncThunk(
  "master/deleteLob",
  async (payload, toolkit) => {
    return await AxiosClient("DELETE", `/master/delete-lob/${payload}`, [], toolkit);
  });

export const getLOBByIdAsync = createAsyncThunk(
  "master/getLobById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-lob/${payload}`, [], toolkit);
  });

  
export const updateLOBAsync = createAsyncThunk(
  "admin/updateLob",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/master/update-lob/${payload.id}`, payload?.info, toolkit);
  }
);