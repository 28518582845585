import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllLeadAsync,
  createLeadAsync,
  getLeadByIdAsync,
  updateLeadAsync,
  deleteLeadAsync,
  updateLeadStatusAsync,
  // me
  getLeadWithoutPaginationAsync,
  getQuotationDropdownAsync,
  getLeadCheckParametersAsync,
  getSuggestionAsync,
  getGstVerifiedApiAsync,
  getLeadListBusinessNameAsync
} from "./lead.async";

const initialState = {
  leadCreateLoader: false,
  leadCreateInfo: {},

  leadListLoader: false,
  leadList: [],
  leadListInfo: {},


  leadSuggestionLoader: false,
  leadSuggestion: [],
  leadSuggestionInfo: {},

  leadByIdLoading: false,
  leadById: {},
  leadByIdInfo: {},

  leadUpdateLoader: false,
  leadUpdateInfo: {},

  leadDeleteLoading: false,
  leadDeleteInfo: {},

  updateLeadStatusLoader: false,
  updateLeadStatusInfo: {},

  leadWithoutPaginationLoader: false,
  leadListWithoutPagination: [],
  leadListWithoutPaginationInfo: {},

  quotationLoader: false,
  quotationList: [],
  quotationInfo: {},

  leadCheckParameterLoading: false,
  leadCheckParameter: {},
  leadCheckParameterInfo: {},

  gstVerifiedLoading: false,
  gstVerifiedInfo: [],

  leadListBusinessNameLoader: false,
  leadListBusinessNameDropdown: [],
  leadListBusinessNameDropdownInfo: {},
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  extraReducers: (builder) => {
    //Lead Check Parameters

    builder.addMatcher(
      isAnyOf(getLeadCheckParametersAsync.pending),
      (state) => {
        state.leadCheckParameterLoading = true;
        state.leadCheckParameterInfo = {};
        state.leadCheckParameter = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadCheckParametersAsync.fulfilled),
      (state, action) => {
        state.leadCheckParameterLoading = false;
        state.leadCheckParameterInfo = {};
        state.leadCheckParameter = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadCheckParametersAsync.rejected),
      (state, action) => {
        state.leadCheckParameterLoading = false;
        state.leadCheckParameterInfo = {};
        state.leadCheckParameter = {};
      }
    );

    // all Lead without page
    builder.addMatcher(
      isAnyOf(getLeadWithoutPaginationAsync.pending),
      (state) => {
        state.leadWithoutPaginationLoader = true;
        state.leadListWithoutPaginationInfo = {};
        state.leadListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.leadWithoutPaginationLoader = false;
        state.leadListWithoutPaginationInfo = {};
        state.leadListWithoutPagination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadWithoutPaginationAsync.rejected),
      (state, action) => {
        state.leadWithoutPaginationLoader = false;
        state.leadListWithoutPaginationInfo = {};
        state.leadListWithoutPagination = [];
      }
    );

    // Qutation dropdown
    builder.addMatcher(isAnyOf(getQuotationDropdownAsync.pending), (state) => {
      state.quotationLoader = true;
      state.quotationInfo = {};
      state.quotationList = [];
    });
    builder.addMatcher(
      isAnyOf(getQuotationDropdownAsync.fulfilled),
      (state, action) => {
        state.quotationLoader = false;
        state.quotationInfo = {};
        state.quotationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationDropdownAsync.rejected),
      (state, action) => {
        state.quotationLoader = false;
        state.quotationInfo = {};
        state.quotationList = [];
      }
    );

    // Lead Create
    builder.addMatcher(isAnyOf(createLeadAsync.pending), (state) => {
      state.leadCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createLeadAsync.fulfilled), (state, action) => {
      state.leadCreateLoader = false;
      state.leadCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createLeadAsync.rejected), (state, action) => {
      state.leadCreateLoader = false;
    });

    //Lead By ID

    builder.addMatcher(isAnyOf(getLeadByIdAsync.pending), (state) => {
      state.leadByIdLoading = true;
      state.leadByIdInfo = {};
      state.leadById = {};
    });
    builder.addMatcher(isAnyOf(getLeadByIdAsync.fulfilled), (state, action) => {
      state.leadByIdLoading = false;
      state.leadByIdInfo = {};
      state.leadById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getLeadByIdAsync.rejected), (state, action) => {
      state.leadByIdLoading = false;
      state.leadByIdInfo = {};
      state.leadById = {};
    });
    // Lead List
    builder.addMatcher(isAnyOf(getAllLeadAsync.pending), (state) => {
      state.leadListLoader = true;
      state.leadListInfo = {};
      state.leadList = [];
    });
    builder.addMatcher(isAnyOf(getAllLeadAsync.fulfilled), (state, action) => {
      state.leadListLoader = false;
      state.leadListInfo = {};
      state.leadList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllLeadAsync.rejected), (state, action) => {
      state.leadListLoader = false;
      state.leadListInfo = {};
      state.leadList = [];
    });
    // Lead Suggestion
    builder.addMatcher(isAnyOf(getSuggestionAsync.pending), (state) => {
      state.leadSuggestionLoader = true;
      state.leadSuggestionInfo = {};
      state.leadSuggestion = [];
    });
    builder.addMatcher(
      isAnyOf(getSuggestionAsync.fulfilled),
      (state, action) => {
        state.leadSuggestionLoader = false;
        state.leadSuggestionInfo = {};
        state.leadSuggestion = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getSuggestionAsync.rejected),
      (state, action) => {
        state.leadListLoSuggestion = false;
        state.leadListSuggestion = {};
        state.leadSuggestion = [];
      }
    );
    //Lead Update
    builder.addMatcher(isAnyOf(updateLeadAsync.pending), (state) => {
      state.leadUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateLeadAsync.fulfilled), (state, action) => {
      state.leadUpdateLoader = false;
      state.leadUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateLeadAsync.rejected), (state, action) => {
      state.leadUpdateLoader = false;
    });
    //Lead Delete
    builder.addMatcher(isAnyOf(deleteLeadAsync.pending), (state) => {
      state.leadDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteLeadAsync.fulfilled), (state, action) => {
      state.leadDeleteLoading = false;
      state.leadDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteLeadAsync.rejected), (state, action) => {
      state.leadDeleteLoading = false;
    });

    //Lead Status Update
    builder.addMatcher(isAnyOf(updateLeadStatusAsync.pending), (state) => {
      state.updateLeadStatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateLeadStatusAsync.fulfilled),
      (state, action) => {
        state.updateLeadStatusLoader = false;
        state.updateLeadStatusInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateLeadStatusAsync.rejected),
      (state, action) => {
        state.updateLeadStatusLoader = false;
      }
    );


    builder.addMatcher(isAnyOf(getLeadListBusinessNameAsync.rejected), (state, action) => {
      state.leadListBusinessNameLoader = false;
      state.leadByIdInfo = {};
      state.leadById = {};
    });
    // Lead List
    builder.addMatcher(isAnyOf(getLeadListBusinessNameAsync.pending), (state) => {
      state.leadListBusinessNameLoader = true;
      state.leadListBusinessNameDropdownInfo = {};
      state.leadListBusinessNameDropdown = [];
    });
    builder.addMatcher(isAnyOf(getLeadListBusinessNameAsync.fulfilled), (state, action) => {
      state.leadListBusinessNameLoader = false;
      state.leadListBusinessNameDropdownInfo = {};
      state.leadListBusinessNameDropdown = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllLeadAsync.rejected), (state, action) => {
      state.leadListBusinessNameLoader = false;
      state.leadListBusinessNameDropdownInfo = {};
      state.leadListBusinessNameDropdown = [];
    });

  },
  reducers: {
    emptyLead: () => initialState ,
    clearLeadSuggestion: (state) => {
      state.leadSuggestion = [];
    }
  }
});
export const { emptyLead , clearLeadSuggestion } = leadSlice.actions;
export default leadSlice.reducer;
