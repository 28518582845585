import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllCurrencyAsync = createAsyncThunk(
  "master/getAllCurrency",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-currency?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const getCurrencyWithoutPaginationAsync = createAsyncThunk(
  "master/getCurrencyWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-currency`,
      [],
      toolkit
    );
  }
);

export const deleteCurrencyAsync = createAsyncThunk(
  "master/deleteCurrency",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-currency/${payload}`,
      [],
      toolkit
    );
  }
);

export const createCurrencyAsync = createAsyncThunk(
  "master/currency",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-currency`,
      payload,
      toolkit
    );
  }
);

export const getCurrencyByIdAsync = createAsyncThunk(
  "master/getCurrencyById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-currency/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateCurrencyAsync = createAsyncThunk(
  "admin/updateCurrency",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-currency/${payload.id}`,
      payload?.info,
      toolkit
    );
  }
);
