import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllExpenseAsync = createAsyncThunk(
  "master/getAllExpense",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/expenses/list-expenses?page=${payload?.page || 1}&limit=${payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const expenseStatusChangeAsync = createAsyncThunk(
  "master/expenseStatusChangeAsync",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/expenses/update-expense/${payload?.id}`, payload, toolkit);
  });

// expenses/update-expense














