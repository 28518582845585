import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

//create Credit Note
export const createCreditNote = createAsyncThunk(
  "invoice/createCreditNote",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/add-remark/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

// getCreditNoteListAsync
export const getCreditNoteListAsync = createAsyncThunk(
  "receipt/list-receipt",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-creditNote?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&customer=${
        payload?.customer || ""
      }&site=${payload?.site || ""}`,
      [],
      toolkit
    );
  }
);

