import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getTotalCustomerCountAreaChartDataAsync } from "./totalCustomerCount.async";

const initialState = {
    totalCustomerCountChartDataLoader: false,
    totalCustomerCountChartDataInfo: {}
};

export const totalCustomerCountSlice = createSlice({
  name: "totalCustomerCount",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getTotalCustomerCountAreaChartDataAsync.pending),
      (state) => {
        state.totalCustomerCountChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getTotalCustomerCountAreaChartDataAsync.fulfilled),
      (state, action) => {
        state.totalCustomerCountChartDataLoader = false;
        state.totalCustomerCountChartDataInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getTotalCustomerCountAreaChartDataAsync.rejected),
      (state, action) => {
        state.totalCustomerCountChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptytotalCustomerCount: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptytotalCustomerCount } = totalCustomerCountSlice.actions;
export default totalCustomerCountSlice.reducer;
