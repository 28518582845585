import React, { useState,useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import CustomBreadcrumbs from "components/custom-breadcrumbs/CustomBreadcrumbs";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { PATH_DASHBOARD } from "routes/paths";
import moment from "moment";

import Iconify from "components/iconify/Iconify";
import CustomTable from "components/CustomTable/CustomTable";
import { useSettingsContext } from "components/settings";
import MenuPopover from "components/menu-popover/index";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "components/confirm-dialog/ConfirmDialog";
import { toast } from "react-hot-toast";
import { toastoptions } from "utils/toastoptions";
import { emptycontract } from "redux/slices/contract/contract.slice";
import {
  deleteContractAsync,
} from "redux/slices/contract/contract.async";

import { getLeadByIdAsync } from "redux/slices/lead/lead.async";

const data = [
  {
    id: "1",
    name: "DE/INJB/07210000010",
    Qun: "1",
  },
  {
    id: "2",
    name: "DE/INJB/07210000010",
    Qun: "1",
  },
];

const LeadContract = () => {
  const dispatch = useDispatch();
  const { leadById = {}, leadByIdLoading } = useSelector(
    (state) => state?.lead
  );
   const {
    contractListLoader,
    contractList = [],
    contractDeleteLoading,
    contractDeleteInfo = {},
  } = useSelector((state) => state?.contract);
  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      width: "90px",
    },
    {
      name: "Quot Number",
      selector: (row) => row?.quotation?.quotationNo,
  },
    {
      name: " Contract Number",
      selector: (row) => row?.contractNo,
    },

    {
      name: "Contract Period",
      selector: (row) => moment(row?.serviceEnd.split("T")[0]).format("DD MMM YYYY") || "N/A",
      
    },
    {
      name: "Status",
      selector: (row) => row?.status || "N/A",
    },
    {
      name: "Services",
      selector: (row) => row?.service?.serviceName,
    },
    {
      name: "Amount",
      selector: (row) => row.amount || "N/A",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row?.serviceStart.split("T")[0]).format("DD MMM YYYY"),
      
    },
    {
      name: " End Date",
      selector: (row) => moment(row?.serviceEnd.split("T")[0]).format("DD MMM YYYY"),
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenPopover(e, row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        );
      },
    },
  ];

  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();

 

  useEffect(() => {
    // for  delete
    if (Object.keys(contractDeleteInfo)?.length) {
      setOpenConfirm(false);
      toast.success(contractDeleteInfo?.message, toastoptions);
      dispatch(emptycontract());
      dispatch(getLeadByIdAsync(leadById?.leadData?._id))
      
      // getAllDataHandler();
    }
  }, [contractDeleteInfo]);
  return (
      <Box sx={{ marginTop: "-70px" }}>
        <Stack alignItems="flex-start">
          <h5>Contract</h5>
        </Stack>
        <CustomTable columns={columns} data={leadById?.contractData} />

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="right-top"
          sx={{ width: 130 }}
        >
        <MenuItem
          onClick={() => {
            navigate(`${PATH_DASHBOARD.editcontract}/${leadById?.contractData?.[0]?._id}`);
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

          <MenuItem
            onClick={() => {
              setOpenConfirm(true);
            }}
            sx={{ color: "error.main" }}
          >
            <Iconify icon="eva:trash-2-outline" />
            Delete
          </MenuItem>
          <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            variant="contained"
            color="error"
            loading={contractDeleteLoading}
            onClick={() => dispatch(deleteContractAsync(leadById?.contractData?.[0]?._id))}
          >
            Delete
          </LoadingButton>
        }
      />
        </MenuPopover>
      </Box>
    
  );
};

export default LeadContract;
