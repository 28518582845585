import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllCredentialAsync,
  createCredentialAsync,
  deleteCredentialAsync,
  getCredentialByIdAsync,
  updateCredentialAsync,
  getAllSitesAsync,
} from "./credential.async";


const initialState = {
  credentialListLoader: false,
  credentialList: [],
  credentialListInfo: {},

  credentialCreateLoader: false,
  credentialCreateInfo: {},

  credentialByIdLoading: false,
  credentialById: {},
  credentialByIdInfo: {},

  credentialUpdateLoader: false,
  credentialUpdateInfo: {},

  credentialDeleteLoading: false,
  credentialDeleteInfo: {},

  siteListLoader: false,
  siteList: [],
  siteListInfo: {},
};

export const credentialSlice = createSlice({
  name: "credential",
  initialState,
  extraReducers: (builder) => {
    // Create Credential
    builder.addMatcher(isAnyOf(createCredentialAsync.pending), (state) => {
      state.credentialCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createCredentialAsync.fulfilled),
      (state, action) => {
        state.credentialCreateLoader = false;
        state.credentialCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createCredentialAsync.rejected),
      (state, action) => {
        state.credentialCreateLoader = false;
      }
    );

    //Credential List
    builder.addMatcher(isAnyOf(getAllCredentialAsync.pending), (state) => {
      state.credentialListLoader = true;
      state.credentialListInfo = {};
      state.credentialList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllCredentialAsync.fulfilled),
      (state, action) => {
        state.credentialListLoader = false;
        state.credentialListInfo = {};
        state.credentialList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCredentialAsync.rejected),
      (state, action) => {
        state.credentialListLoader = false;
        state.credentialListInfo = {};
        state.credentialList = [];
      }
    );

    //Credential Delete
    builder.addMatcher(isAnyOf(deleteCredentialAsync.pending), (state) => {
      state.credentialDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteCredentialAsync.fulfilled),
      (state, action) => {
        state.credentialDeleteLoading = false;
        state.credentialDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteCredentialAsync.rejected),
      (state, action) => {
        state.credentialDeleteLoading = false;
      }
    );

    //Credential By Id
    builder.addMatcher(isAnyOf(getCredentialByIdAsync.pending), (state) => {
      state.credentialByIdLoading = true;
      state.credentialByIdInfo = {};
      state.credentialById = {};
    });
    builder.addMatcher(
      isAnyOf(getCredentialByIdAsync.fulfilled),
      (state, action) => {
        state.credentialByIdLoading = false;
        state.credentialByIdInfo = {};
        state.credentialById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCredentialByIdAsync.rejected),
      (state, action) => {
        state.credentialByIdLoading = false;
        state.credentialByIdInfo = {};
        state.credentialById = {};
      }
    );

    //Credential Update
    builder.addMatcher(isAnyOf(updateCredentialAsync.pending), (state) => {
      state.credentialUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateCredentialAsync.fulfilled),
      (state, action) => {
        state.credentialUpdateLoader = false;
        state.credentialUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(updateCredentialAsync.rejected), (state, action) => {
      state.credentialUpdateLoader = false;
    });

    // for site list
    builder.addMatcher(isAnyOf(getAllSitesAsync.pending), (state) => {
      state.siteListLoader = true;
      state.siteListInfo = {};
      state.siteList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllSitesAsync.fulfilled),
      (state, action) => {
        state.siteListLoader = false;
        state.siteListInfo = {};
        state.siteList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllSitesAsync.rejected),
      (state, action) => {
        state.siteListLoader = false;
        state.siteListInfo = {};
        state.siteList = [];
      }
    );
  },
  reducers: {
    emptyCredential: () => initialState
  }
});
export const { emptyCredential } = credentialSlice.actions;

export default credentialSlice.reducer;
