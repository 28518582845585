import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllInboundAsync,
  deleteInboundAsync,
  CreateInboundAsync,
  getInboundByIdAsync,
  updateInboundAsync,
  getPuchaseOrderByVendorAsync,
  getItemSkuDropdownAsync,
} from "./inbound.async";

const initialState = {
  inboundListLoader: false,
  inboundList: [],
  inboundListInfo: {},

  inboundDeleteLoading: false,
  inboundDeleteInfo: {},

  inboundCreateLoader: false,
  inboundCreateInfo: {},

  inboundByIdLoading: false,
  inboundById: {},
  inboundByIdInfo: {},

  inboundUpdateLoader: false,
  inboundUpdateInfo: {},

  purchaseListbyVendorLoader: false,
  purchasebyVendorList: [],
  purchaseListbyVendorInfo: {},

 

  itemSkuListLoader: false,
  itemSkuList: [],
  itemSkuListInfo: {},
};

export const inboundSlice = createSlice({
  name: "inbound",
  initialState,
  extraReducers: (builder) => {
    // inbound list
    builder.addMatcher(isAnyOf(getAllInboundAsync.pending), (state) => {
      state.inboundListLoader = true;
      state.inboundListInfo = {};
      state.inboundList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllInboundAsync.fulfilled),
      (state, action) => {
        state.inboundListLoader = false;
        state.inboundListInfo = {};
        state.inboundList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInboundAsync.rejected),
      (state, action) => {
        state.inboundListLoader = false;
        state.inboundListInfo = {};
        state.inboundList = [];
      }
    );

    //inbound Delete
    builder.addMatcher(isAnyOf(deleteInboundAsync.pending), (state) => {
      state.inboundDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteInboundAsync.fulfilled),
      (state, action) => {
        state.inboundDeleteLoading = false;
        state.inboundDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteInboundAsync.rejected),
      (state, action) => {
        state.inboundDeleteLoading = false;
      }
    );

    // inbound country
    builder.addMatcher(isAnyOf(CreateInboundAsync.pending), (state) => {
      state.inboundCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(CreateInboundAsync.fulfilled),
      (state, action) => {
        state.inboundCreateLoader = false;
        state.inboundCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(CreateInboundAsync.rejected),
      (state, action) => {
        state.inboundCreateLoader = false;
      }
    );

    //inbound By Id
    builder.addMatcher(isAnyOf(getInboundByIdAsync.pending), (state) => {
      state.inboundByIdLoading = true;
      state.inboundByIdInfo = {};
      state.inboundById = {};
    });
    builder.addMatcher(
      isAnyOf(getInboundByIdAsync.fulfilled),
      (state, action) => {
        state.inboundByIdLoading = false;
        state.inboundByIdInfo = {};
        state.inboundById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getInboundByIdAsync.rejected),
      (state, action) => {
        state.inboundByIdLoading = false;
        state.inboundByIdInfo = {};
        state.inboundderById = {};
      }
    );

    // Update inbound
    builder.addMatcher(isAnyOf(updateInboundAsync.pending), (state) => {
      state.inboundUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateInboundAsync.fulfilled),
      (state, action) => {
        state.inboundUpdateLoader = false;
        state.inboundUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInboundAsync.rejected),
      (state, action) => {
        state.inboundUpdateLoader = false;
      }
    );

    // po number list by vendor
    builder.addMatcher(
      isAnyOf(getPuchaseOrderByVendorAsync.pending),
      (state) => {
        state.purchaseListbyVendorLoader = true;
        state.purchaseListbyVendorInfo = {};
        state.purchasebyVendorList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getPuchaseOrderByVendorAsync.fulfilled),
      (state, action) => {
        state.purchaseListbyVendorLoader = false;
        state.purchaseListbyVendorInfo = {};
        state.purchasebyVendorList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getPuchaseOrderByVendorAsync.rejected),
      (state, action) => {
        state.purchaseListbyVendorLoader = false;
        state.purchaseListbyVendorInfo = {};
        state.purchasebyVendorList = [];
      }
    );

    //  ItemSku list
    builder.addMatcher(isAnyOf(getItemSkuDropdownAsync.pending), (state) => {
      state.itemSkuListLoader = true;
      state.itemSkuListInfo = {};
      state.itemSkuList = [];
    });
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.fulfilled),
      (state, action) => {
        state.itemSkuListLoader = false;
        state.itemSkuListInfo = {};
        state.itemSkuList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.rejected),
      (state, action) => {
        state.itemSkuListLoader = false;
        state.itemSkuListInfo = {};
        state.itemSkuList = [];
      }
    );
  },
  reducers: {
    emptyinbound: () => initialState,
  },
});
export const { emptyinbound } = inboundSlice.actions;

export default inboundSlice.reducer;
