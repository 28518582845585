import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllStateAsync = createAsyncThunk(
  "master/getAllLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-states?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);
export const getAllStateWithoutPaginationAsync = createAsyncThunk(
  "master/getAllLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-states`,
      [],
      toolkit
    );
  }
);

//create post

export const createStateAsync = createAsyncThunk(
  "master/state",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-state`, payload, toolkit);
  }
);

//getByID State
export const getStateByIdAsync = createAsyncThunk(
  "master/getStateById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-state/${payload}`, [], toolkit);
  });

  //update State
  export const updateStateAsync = createAsyncThunk(
    "admin/updateLob",
    async (payload, toolkit) => {
      return await AxiosClient("PUT", `/master/update-state/${payload.id}`, payload, toolkit);
    }
  );

   //Delete State

  export const deleteStateAsync = createAsyncThunk(
    "master/deleteLob",
    async (payload, toolkit) => {
      return await AxiosClient("DELETE", `/master/delete-state/${payload}`, [], toolkit);
    });


    //getState By CountryId
    export const getStateByCountryIdAsync = createAsyncThunk(
      "master/getStateByCountry",
      async (payload, toolkit) => {
        return await AxiosClient("GET",`/master/lookup-states?countryId=${payload?.countryid}`, [], toolkit);
      });
  



