import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getProfitabilityChartChartDataAsync } from "./profitability.async";

const initialState = {
  profitabilityChartDataLoader: false,
  profitabilityChartDataInfo: {}
};

export const profitabilityChartSlice = createSlice({
  name: "profitabilityChart",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getProfitabilityChartChartDataAsync.pending),
      (state) => {
        state.profitabilityChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getProfitabilityChartChartDataAsync.fulfilled),
      (state, action) => {
        state.profitabilityChartDataLoader = false;
        state.profitabilityChartDataInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getProfitabilityChartChartDataAsync.rejected),
      (state, action) => {
        state.profitabilityChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptyMis: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyProfitabilityChart } = profitabilityChartSlice.actions;
export default profitabilityChartSlice.reducer;
