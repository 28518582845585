import CustomComponentLoader from "components/CustomComponentLoader/CustomComponentLoader";
import React from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

const CustomTable = ({
  columns,
  data,
  loader,
  columnheight,
  totalcount,
  onChangeRowsPerPage,
  onChangePage,
  expandableRows,
  expandableRowsComponent,
  paginationDefaultPage,  // Add this prop
}) => {
  const { getIndividualSetting } = useSelector(
    (state) => state.individualSetting
  );
  const customStyles = {
    rows: {
      style: {
        whiteSpace: "normal",
        minHeight: "50px",
        fontSize: "14px",
        fontFamily: "Abel",
      },
    },
    headCells: {
      style: {
        background: "#F2F5F7",
      },
    },
    cells: {
      style: {
        height: getIndividualSetting?.dense === "100%" ? "90px" : columnheight,
      },
    },
  };
  return (
    <>
      <DataTable
        className="rounded-0"
        responsive
        pagination
        paginationServer
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        columns={columns}
        data={data}
        customStyles={customStyles}
        progressPending={loader}
        progressComponent={<CustomComponentLoader padding="20px 0" size={40} />}
        persistTableHead={true}
        fixedHeader = {true}
        fixedHeaderScrollHeight="400px"
        paginationTotalRows={totalcount}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationRowsPerPageOptions = {[10,25,50,100,200,300,400,500]}
        paginationDefaultPage={paginationDefaultPage}  // Use this prop
        // sortIcon={<AiOutlineSortAscending />}    
      />
     </>
  );
};

export default React.memo(CustomTable);
