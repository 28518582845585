import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllInboundAsync = createAsyncThunk(
  "inbound/getAllInbound",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inbound/list-inbounds?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deleteInboundAsync = createAsyncThunk(
  "inbound/deleteInbound",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/inbound/delete-inbound/${payload}`,
      [],
      toolkit
    );
  }
);

export const CreateInboundAsync = createAsyncThunk(
  "inbound/InboundCountryAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/inbound/create-inbound`,
      payload,
      toolkit
    );
  }
);

export const getInboundByIdAsync = createAsyncThunk(
  "inbound/getInboundByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inbound/get-inbound/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateInboundAsync = createAsyncThunk(
  "inbound/updateInboundAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/inbound/update-inbound/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// get PO by vendor
export const getPuchaseOrderByVendorAsync = createAsyncThunk(
  "inbound/getPuchaseOrderByVendorAsync",
  async (payload, toolkit) => {
     return await AxiosClient(
      "GET",
      `/inbound/list-ponumber?suppliertype=${payload?.type}`,
       [],
      toolkit
    );
  }
);

//  itlemSku dropdown
export const getItemSkuDropdownAsync = createAsyncThunk(
  "inbound/getItemSkuDropdownAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inbound/list-itemssku/${payload?._id}`,
      [],
      toolkit
    );
  }
);
