import { IconButton, Typography, Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Iconify from 'components/iconify/Iconify';
import * as yup from 'yup';
import { capitalize } from 'lodash';
import CopyButton from 'components/Custom-Text-Copied/CopyButton';
import moment from 'moment';
import Label from 'components/label/Label';

export const ContainerReportsColumnHandler = ({
  pageNumber,
  setEntity,
  setOpen,
}) => {
  const column = [
    {
      name: 'Sr No.',
      selector: (row, index) =>
        pageNumber === 1
          ? index + 1
          : index === 9
          ? `${pageNumber}0`
          : `${pageNumber - 1}${index + 1}`,
      width: '80px',
    },
    {
      name: 'Cert. No.',
      selector: (row) => {
        const certNo = capitalize(row?.certNo ?? 'N/A');
        return (
          <Tooltip
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {certNo}
                <CopyButton textToCopy={certNo} />
              </Box>
            }
          >
            <Typography component='div' noWrap sx={{ fontSize: '14px' }}>
              {certNo}
            </Typography>
          </Tooltip>
        );
      },
      width: '220px',
    },
    {
      name: 'Cert. Date',
      selector: (row) => {
        const certDate = row?.certDate ?? 'N/A';
        return (
          <Tooltip
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {certDate}
                <CopyButton textToCopy={certDate} />
              </Box>
            }
          >
            <Typography component='div' noWrap sx={{ fontSize: '14px' }}>
              {certDate}
            </Typography>
          </Tooltip>
        );
      },
      width: '220px',
    },
    {
      name: 'Job.No.',
      selector: (row) => {
        const jobNo = capitalize(row?.jobNo ?? 'N/A');
        return (
          <Tooltip
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {jobNo}
                <CopyButton textToCopy={jobNo} />
              </Box>
            }
          >
            <Typography component='div' noWrap sx={{ fontSize: '14px' }}>
              {jobNo}
            </Typography>
          </Tooltip>
        );
      },
      width: '220px',
    },

    {
      name: 'Container No.',
      selector: (row) => {
        const containerNo = row?.containerNo[0]?.length ?? 0;
        return (
          <Label
            variant='soft'
            color='success'
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#fff',
              color: '#00bcd4',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (containerNo !== 0) {
                setEntity(row);
                setOpen(true);
              }
            }}
          >
            {row?.containerNo[0]?.length ?? 0}
          </Label>
        );
      },
      width: '200px',
    },
    {
      name: 'Port of Discharge',
      selector: (row) => {
        return (
          <Tooltip title={row?.portOfDischarge ?? 'N/A'}>
            <Typography component='div' noWrap sx={{ fontSize: '14px' }}>
              {row?.portOfDischarge ?? 'N/A'}
            </Typography>
          </Tooltip>
        );
      },
      width: '200px',
    },
  ];

  return column;
};
export const _initial = {
  stateName: '',
  countryName: {},
};

export const _validate = yup.object().shape({
  stateName: yup.string().required(),

  countryName: yup.object({
    label: yup.string().required(),
  }),
});
