import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllAccreditationAsync = createAsyncThunk(
  "master/getAllAccreditation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-accreditation?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&chemical=${payload?.chemical || ""}&site=${payload?.site}`,
      [],
      toolkit
    );
  }
);

export const createAccreditationAsync = createAsyncThunk(
  "master/Accreditation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-accreditation`,
      payload,
      toolkit
    );
  }
);

export const deleteAccreditationAsync = createAsyncThunk(
  "master/deleteAccreditation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-accreditation/${payload}`,
      [],
      toolkit
    );
  }
);

export const getAccreditationByIdAsync = createAsyncThunk(
  "master/getAccreditationById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-accreditation/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateAccreditationAsync = createAsyncThunk(
  "admin/updateAccreditation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-accreditation/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// for site
export const getAllSitesAsync = createAsyncThunk(
  "master/getAllSites",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-site`, [], toolkit);
  }
);

// for service

export const getAllServiceAsync = createAsyncThunk(
  "master/getAllService",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-service`, [], toolkit);
  }
);

// for  all city

export const getAllCityAsync = createAsyncThunk(
  "master/getAllcity",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-city`,
      [],
      toolkit
    );
  }
);
