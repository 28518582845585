import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getProfitabilityChartChartDataAsync = createAsyncThunk(
  "mis/getProfitabilityChartChartData",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-profitability-chart-data`,
      payload,
      toolkit
    );
  }
);
