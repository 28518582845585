import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllInventoryAsync = createAsyncThunk(
  "master/getAllInventory",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-inventory?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createInventoryAsync = createAsyncThunk(
  "createInventory",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-inventory`,
      payload,
      toolkit
    );
  }
);

export const getInventoryByIdAsync = createAsyncThunk(
  "master/getInventoryById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-inventory/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateInventoryAsync = createAsyncThunk(
  "master/updateInventory",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-inventory/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const deleteInventorysync = createAsyncThunk(
  "master/deleteInventory",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-inventory/${payload}`,
      [],
      toolkit
    );
  }
);

// get Inventory without pagination

export const getInventoryWithoutPaginationAsync = createAsyncThunk(
  "master/getInventoryWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-inventory`, [], toolkit);
  }
);

//  Inventory Chemicals
export const getInventoryChemicalAsync = createAsyncThunk(
  "master/list-chemical",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-chemical`, [], toolkit);
  }
);

export const getAllInventoryWithOutPaginateAsync = createAsyncThunk(
  "master/getAllInventoryWithOutPaginate",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-inventory`, [], toolkit);
  }
);


export const getAllInventoryWithChemicalAsync = createAsyncThunk(
  "master/getAllInventoryWithChemicalAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-inventory?search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);
export const getAllInventoryJobsAsync = createAsyncThunk(
  "master/getAllInventoryWithChemicalAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inventoryallocation/get-jobs/${payload || ""}`,
      [],
      toolkit
    );
  }
);
