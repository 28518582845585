import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllCertificateAsync,
  getJobDropdownByCustomerIdAsync,
  getCertificatesOfJobAsync,
  createCertificateAsync,
  getCertificateDocumentDropdownAsync,
  getCertificateByIdAsync,
  getCertificateDocumentHtmlByDocumentIdAsync,
  createCertificateCheckBoxAsync,
  certificateEmailPdfAsync
} from "./certificate.async";

const initialState = {
  certificateListLoader: false,
  certificateList: [],
  certificateListInfo: {},

  jobDropdownByCustomerIdByIdLoading: false,
  jobDropdownByCustomerIdByIdList: {},
  jobDropdownByCustomerIdByIdInfo: {},

  certificatesOfJobLoading: false,
  certificatesOfJobLoadingList: {},
  certificatesOfJobLoadingInfo: {},

  certificateCreateLoader: false,
  certificateCreateInfo: {},

  certificateDocumentDropDownLoader: false,
  certificateDocumentDropDown: [],

  certificateByIdLoading: false,
  certificateById: {},
  certificateByIdInfo: {},

  contractDocumentHtmlByDocumentIdLoading: false,
  contractDocumentHtmlByDocumentId: {},
  contractDocumentHtmlByDocumentIdInfo: {},

  certificateCreateCheckBoxLoader: false,
  certificateCreateCheckBoxInfo: {},


  certificateEmailPdfLoader: false,
  certificateEmailPdfInfo: {}
};

export const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  extraReducers: (builder) => {


 //Certificate Email Pdf
 builder.addMatcher(isAnyOf(certificateEmailPdfAsync.pending), (state) => {
  state.certificateEmailPdfLoader = true;
});
builder.addMatcher(
  isAnyOf(certificateEmailPdfAsync.fulfilled),
  (state, action) => {
    state.certificateEmailPdfLoader = false;
    state.certificateEmailPdfInfo = action.payload;
  }
);
builder.addMatcher(
  isAnyOf(certificateEmailPdfAsync.rejected),
  (state, action) => {
    state.certificateEmailPdfLoader = false;
  }
);



    // create Certificate Check Box
    builder.addMatcher(
      isAnyOf(createCertificateCheckBoxAsync.pending),
      (state) => {
        state.certificateCreateCheckBoxLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(createCertificateCheckBoxAsync.fulfilled),
      (state, action) => {
        state.certificateCreateCheckBoxLoader = false;
        state.certificateCreateCheckBoxInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createCertificateCheckBoxAsync.rejected),
      (state, action) => {
        state.certificateCreateCheckBoxLoader = false;
      }
    );
    // create Certificate
    builder.addMatcher(isAnyOf(createCertificateAsync.pending), (state) => {
      state.certificateCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createCertificateAsync.fulfilled),
      (state, action) => {
        state.certificateCreateLoader = false;
        state.certificateCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createCertificateAsync.rejected),
      (state, action) => {
        state.certificateCreateLoader = false;
      }
    );

    // Certificates Of Job
    builder.addMatcher(isAnyOf(getCertificatesOfJobAsync.pending), (state) => {
      state.certificatesOfJobLoading = true;
      state.certificatesOfJobLoadingInfo = {};
      state.certificatesOfJobLoadingList = [];
      state.certificateListLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getCertificatesOfJobAsync.fulfilled),
      (state, action) => {
        state.certificatesOfJobLoading = false;
        state.certificatesOfJobLoadingInfo = {};
        state.certificatesOfJobLoadingList = action.payload || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificatesOfJobAsync.rejected),
      (state, action) => {
        state.certificatesOfJobLoading = false;
        state.certificatesOfJobLoadingInfo = {};
        state.certificatesOfJobLoadingList = [];
      }
    );

    // Job Dropdown by Customer Id
    builder.addMatcher(
      isAnyOf(getJobDropdownByCustomerIdAsync.pending),
      (state) => {
        state.jobDropdownByCustomerIdByIdLoading = true;
        state.jobDropdownByCustomerIdByIdInfo = {};
        state.jobDropdownByCustomerIdByIdList = [];
        state.certificateListLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getJobDropdownByCustomerIdAsync.fulfilled),
      (state, action) => {
        state.jobDropdownByCustomerIdByIdLoading = false;
        state.jobDropdownByCustomerIdByIdInfo = {};
        state.jobDropdownByCustomerIdByIdList = action.payload || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getJobDropdownByCustomerIdAsync.rejected),
      (state, action) => {
        state.jobDropdownByCustomerIdByIdLoading = false;
        state.jobDropdownByCustomerIdByIdInfo = {};
        state.jobDropdownByCustomerIdByIdList = [];
      }
    );

    //Certificate  List
    builder.addMatcher(isAnyOf(getAllCertificateAsync.pending), (state) => {
      state.certificateListLoader = true;
      state.certificateListInfo = {};
      state.certificateList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllCertificateAsync.fulfilled),
      (state, action) => {
        state.certificateListLoader = false;
        state.certificateListInfo = {};
        state.certificateList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCertificateAsync.rejected),
      (state, action) => {
        state.certificateListLoader = false;
        state.certificateListInfo = {};
        state.certificateList = [];
      }
    );

    // Document document Drop Down List

    builder.addMatcher(
      isAnyOf(getCertificateDocumentDropdownAsync.pending),
      (state) => {
        state.certificateDocumentDropDownLoader = true;
        state.contractDocumentDropDown = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificateDocumentDropdownAsync.fulfilled),
      (state, action) => {
        state.certificateDocumentDropDownLoader = false;
        state.certificateDocumentDropDown = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificateDocumentDropdownAsync.rejected),
      (state, action) => {
        state.certificateDocumentDropDownLoader = false;
        state.certificateDocumentDropDown = [];
      }
    );

    //Certificate By Id
    builder.addMatcher(isAnyOf(getCertificateByIdAsync.pending), (state) => {
      state.certificateByIdLoading = true;
      state.certificateByIdInfo = {};
      state.certificateById = {};
    });
    builder.addMatcher(
      isAnyOf(getCertificateByIdAsync.fulfilled),
      (state, action) => {
        state.certificateByIdLoading = false;
        state.certificateByIdInfo = {};
        state.certificateById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificateByIdAsync.rejected),
      (state, action) => {
        state.certificateByIdLoading = false;
        state.certificateByIdInfo = {};
        state.certificateById = {};
      }
    );

    //Document Html By Document Id
    builder.addMatcher(
      isAnyOf(getCertificateDocumentHtmlByDocumentIdAsync.pending),
      (state) => {
        state.certificateDocumentHtmlByDocumentIdLoading = true;
        state.certificateDocumentHtmlByDocumentIdInfo = {};
        state.certificateDocumentHtmlByDocumentId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificateDocumentHtmlByDocumentIdAsync.fulfilled),
      (state, action) => {
        state.certificateDocumentHtmlByDocumentIdLoading = false;
        state.certificateDocumentHtmlByDocumentIdInfo = {};
        state.certificateDocumentHtmlByDocumentId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCertificateDocumentHtmlByDocumentIdAsync.rejected),
      (state, action) => {
        state.certificateDocumentHtmlByDocumentIdLoading = false;
        state.certificateDocumentHtmlByDocumentIdInfo = {};
        state.certificateDocumentHtmlByDocumentId = {};
      }
    );
  },
  reducers: {
    emptyCertificate: (state) => {
      return {
        ...initialState,
        certificatesOfJobLoadingList: [] // Delete the data inside certificatesOfJobLoadingList
      };
    }
  }
});
export const { emptyCertificate } = certificateSlice.actions;
export default certificateSlice.reducer;
