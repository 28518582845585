import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllActivitiesAsync = createAsyncThunk(
  "master/getAllActivitiesAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-activity?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createActivitiesAsync = createAsyncThunk(
  "master/createActivitiesAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-activity`,
      payload,
      toolkit
    );
  }
);

export const updateActivitiesAsync = createAsyncThunk(
  "admin/updateActivitiesAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-activity/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const getActivitiesByIdAsync = createAsyncThunk(
  "master/getActivitiesByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-activity/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteActivitiesAsync = createAsyncThunk(
  "master/deleteActivitiesAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-activity/${payload}`,
      [],
      toolkit
    );
  }
);
