import * as yup from "yup";
import { capitalize } from "lodash";
import moment from "moment";
export const _followType = [
  {
    value: "call",
    label: "Call"
  },
  {
    value: "email",
    label: "Email"
  }
];

export const Notescolumns = ({pageNumber}) => {
  const column =[
  
  {
    name: "Sl No.",
    selector: (row, index) =>
      pageNumber === 1
        ? index + 1
        : index === 9
        ? `${pageNumber}0`
        : `${pageNumber - 1}${index + 1}`,
    width: "80px"
  },
  {
    name: "Notes ",
    selector: (row) => capitalize(row.title)
  },
  {
    name: "Added On",
    selector: (row) =>
      moment(row.updatedAt.split("T")[0]).format("DD MMM YYYY"),
      width: "200px"
  },
  {
    name: " Added By",
    selector: (row) => `${row.createdBy?.firstName ?? ""} ${row.createdBy?.lastName ?? "N/A"}`,
    width: "180px"
  }
];
return column
}

// Formik for Create Follow Up
export const _initial = {
  type: {},
  nextFollowupDate: "",
  nextFollowupTime: "",
  assignTo: {},
  followupResponse: ""
};
export const _validate = yup.object().shape({
  type: yup.object({
    label: yup.string().required()
  }),
  nextFollowupDate: yup.string().required(),
  nextFollowupTime: yup.string().required(),
  assignTo: yup.object({
    label: yup.string().required()
  }),
  followupResponse: yup.string().required()
});

// Notes Formik
export const initial = {
  title: ""
};

export const validate = yup.object().shape({
  title: yup.string().required()
});
