import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

//  getAllSitesWithoutPaginationAsync
export const getAllSitesWithoutPaginationAsync = createAsyncThunk(
  "master/getAllSitesWithoutPaginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-site `, [], toolkit);
  }
);

//  getAllSitesAsync
export const getAllSitesAsync = createAsyncThunk(
  "master/getAllSites",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-site?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

//create post

export const createSitesAsync = createAsyncThunk(
  "master/state",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-site`, payload, toolkit);
  }
);

//getByID State
export const getSitesByIdAsync = createAsyncThunk(
  "master/getStateById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-site/${payload}`, [], toolkit);
  }
);

//update State
export const updateSitesAsync = createAsyncThunk(
  "admin/updateLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-site/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//Delete Sites

export const deleteSitesAsync = createAsyncThunk(
  "master/deleteLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-site/${payload}`,
      [],
      toolkit
    );
  }
);

//for pincode
export const getAllPinCodeAsync = createAsyncThunk(
  "master/getAllPinCode",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-pincode`, [], toolkit);
  }
);

// for Country

export const getAllCountryAsync = createAsyncThunk(
  "master/getAllCountry",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-country`, [], toolkit);
  }
);

// for LOB
export const getAllLOBAsync = createAsyncThunk(
  "master/getAllLob",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-lob`, [], toolkit);
  }
);

//  Site By Role
export const getSiteByRoleAsync = createAsyncThunk(
  "master/getSiteByRoleAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-site-dropdown `,
      [],
      toolkit
    );
  }
);
