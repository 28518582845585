import React from "react";
import { Box, Card, Container, Grid, TextField, Stack } from "@mui/material";
import { useSettingsContext } from "components/settings";
import { PATH_DASHBOARD } from "routes/paths";
import CustomBreadcrumbs from "components/custom-breadcrumbs";
import { useFormik } from "formik";
import _, { capitalize } from "lodash";

// api
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  createCountryAsync,
  getCountryByIdAsync,
  updateCountryAsync,
  getAllCurrencyAsync
} from "redux/slices/country/country.async";
import { getCurrencyWithoutPaginationAsync } from "redux/slices/currency/currency.async";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import { emptyCountry } from "redux/slices/country/country.slice";
import { _initial, _validate } from "./util";
import AutoCompleteCustom from "components/AutoCompleteCustom/AutoCompleteCustom";

const CreateState = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();

  const {
    countryCreateLoader,
    countryUpdateLoader,
    countryById = {},
    countryCreateInfo,
    countryUpdateInfo
  } = useSelector((state) => state.country);
  const { currencyWithoutPaginationLoader, currencyListWithoutPagination } =
    useSelector((state) => state.currency);

  const handleReturn = () => {
    navigate(PATH_DASHBOARD.country);
  };

  const onSubmit = async (values) => {
    const payload = {
      id,
      countryName: values?.countryName,
      phoneCode: values?.phoneCode,
      currencyCode: values?.currencyCode?.value
    };

    if (id) {
      dispatch(updateCountryAsync(payload));
    } else {
      // payload.id
      dispatch(createCountryAsync(payload));
    }
  };

  const formik = useFormik({
    initialValues: _initial,
    onSubmit,
    validationSchema: _validate
  }); // FOMRIK

  useEffect(() => {
    if (id) dispatch(getCountryByIdAsync(id));
  }, []);

  useEffect(() => {
    //  create
    if (countryCreateInfo?.success) {
      toast.success(countryCreateInfo?.message, toastoptions);
      dispatch(emptyCountry());
      navigate(PATH_DASHBOARD.country);
    }
    //  update
    if (countryUpdateInfo?.success) {
      toast.success(countryUpdateInfo?.message, toastoptions);
      dispatch(emptyCountry());
      navigate(PATH_DASHBOARD.country);
    }
  }, [countryCreateInfo, countryUpdateInfo]);

  useEffect(() => {
    if (id && countryById) {
      formik.setFieldValue("countryName", countryById?.countryName);
      formik.setFieldValue("phoneCode", countryById?.phoneCode);

      formik.setFieldValue("currencyCode", {
        label: capitalize(countryById?.currencyCode?.currencyCode),

        value: countryById?.currencyCode?._id
      });
    }
  }, [id, countryById]);

  useEffect(() => {
    dispatch(getCurrencyWithoutPaginationAsync());
  }, []);

  const isEdit = location?.pathname?.split("/").includes("edit");
  const isCreateCountry = location?.pathname
    ?.split("/")
    .includes("createcountry");
  const countryCreate = isCreateCountry ? true : isEdit;

  return (
    <>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading={
            Boolean(id) && !(isCreateCountry || isEdit)
              ? "View Country"
              : Boolean(id)
              ? "Update Country"
              : "Create Country"
          }
          links={[
            { name: "Master" },
            { name: "Country", href: PATH_DASHBOARD.country },
            {
              name: isCreateCountry
                ? "Create Country"
                : isEdit
                ? "Update Country"
                : "View Country"
            }
          ]}
        />
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Box
                    rowGap={5}
                    columnGap={4}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)"
                    }}
                  >
                    <TextField
                      name="countryName"
                      label="Country Name"
                      type="text"
                      {...formik.getFieldProps("countryName")}
                      onChange={(e) => {
                        formik.setFieldValue("countryName", e.target.value);
                      }}
                      error={
                        formik.touched.countryName && formik.errors.countryName
                      }
                      disabled={!countryCreate}
                    />
                    <TextField
                      name="phoneCode"
                      label="Phone Code"
                      type="number"
                      {...formik.getFieldProps("phoneCode")}
                      onChange={(e) => {
                        formik.setFieldValue("phoneCode", e.target.value);
                      }}
                      onWheel={(e) => e.target.blur()}
                      error={
                        formik.touched.phoneCode && formik.errors.phoneCode
                      }
                      disabled={!countryCreate}
                    />

                    <AutoCompleteCustom
                      name="currencyCode"
                      loading={currencyWithoutPaginationLoader}
                      options={_?.map(
                        currencyListWithoutPagination?.data,
                        (ev) => {
                          return {
                            label: ev?.currencyCode,
                            value: ev?._id
                          };
                        }
                      )}
                      value={formik.values.currencyCode}
                      onChange={(event, value) => {
                        formik.setFieldValue("currencyCode", value || []);
                      }}
                      label="Select Currency Code"
                      error={
                        formik.touched.currencyCode &&
                        formik.errors.currencyCode
                      }
                      disabled={!countryCreate}
                    />
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 2 }}>
                    <Box sx={{ display: "flex" }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={id ? countryUpdateLoader : countryCreateLoader}
                        sx={{ mr: 2 }}
                        disabled={!countryCreate}
                      >
                        {id ? "Update" : "Create"}
                      </LoadingButton>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleReturn}
                      >
                        Back
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default CreateState;
