import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getReceiptListAsync,
  createReceiptAsync,
  getReceiptAsync,
  updateReceiptAsync,
  getAllInvoiceForReceiptAsync,
  cancelReceiptInvoiceAsync
} from "./receipt.async";

const initialState = {
  receiptListLoader: false,
  receiptList: [],
  totalreceipt: 0,
  receiptListInfo: {},

  invoiceRListLoader: false,
  invoiceRList: [],

  receiptCreateLoader: false,
  receiptCreateInfo: {},

  receiptByIdLoading: false,
  receiptById: {},

  receiptUpdateLoader: false,
  receiptUpdateInfo: {},

  cancelReceiptLoader: false,
  cancelReceipt: {},
};

export const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  extraReducers: (builder) => {
    // Get Receipt List
    builder.addMatcher(isAnyOf(getReceiptListAsync.pending), (state) => {
      state.receiptListLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getReceiptListAsync.fulfilled),
      (state, action) => {
        state.receiptListLoader = false;
        state.receiptList = action.payload?.data?.response;
        state.totalreceipt = action.payload?.data?.totalItems;
      }
    );
    builder.addMatcher(
      isAnyOf(getReceiptListAsync.rejected),
      (state, action) => {
        state.receiptListLoader = false;
      }
    );

    // Get invoice list for receipt
    builder.addMatcher(
      isAnyOf(getAllInvoiceForReceiptAsync.pending),
      (state) => {
        state.invoiceRListLoader = true;
        state.invoiceRList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInvoiceForReceiptAsync.fulfilled),
      (state, action) => {
        state.invoiceRListLoader = false;
        state.invoiceRList = action.payload || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInvoiceForReceiptAsync.rejected),
      (state, action) => {
        state.invoiceRListLoader = false;
        state.invoiceRList = [];
      }
    );

    // Create Receipt
    builder.addMatcher(isAnyOf(createReceiptAsync.pending), (state) => {
      state.receiptCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createReceiptAsync.fulfilled),
      (state, action) => {
        state.receiptCreateLoader = false;
        state.receiptCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createReceiptAsync.rejected),
      (state, action) => {
        state.receiptCreateLoader = false;
      }
    );

    // Get Receipt By Id
    builder.addMatcher(isAnyOf(getReceiptAsync.pending), (state) => {
      state.receiptByIdLoading = true;
    });
    builder.addMatcher(isAnyOf(getReceiptAsync.fulfilled), (state, action) => {
      state.receiptByIdLoading = false;
      state.receiptById = action.payload?.data;
      state.totalreceipt = action.payload?.data?.totalItems;
    });
    builder.addMatcher(isAnyOf(getReceiptAsync.rejected), (state, action) => {
      state.receiptByIdLoading = false;
    });

    // Receipt Update
    builder.addMatcher(isAnyOf(updateReceiptAsync.pending), (state) => {
      state.receiptUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateReceiptAsync.fulfilled),
      (state, action) => {
        state.receiptUpdateLoader = false;
        state.receiptUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateReceiptAsync.rejected),
      (state, action) => {
        state.receiptUpdateLoader = false;
      }
    );
    // Receipt Cancel
    builder.addMatcher(isAnyOf(cancelReceiptInvoiceAsync.pending), (state) => {
      state.cancelReceiptLoader = true;
    });
    builder.addMatcher(
      isAnyOf(cancelReceiptInvoiceAsync.fulfilled),
      (state, action) => {
        state.cancelReceiptLoader = false;
        state.cancelReceipt = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(cancelReceiptInvoiceAsync.rejected),
      (state, action) => {
        state.receiptUpdateLoader = false;
      }
    );
  },
  reducers: {
    emptyReceipt: (state) => {
      return {
        ...initialState,
      };
    },
    emptyInvoiceListForReceipt: (state) => {
      state.invoiceRList = [];
    },
    emptyReceiptById: (state) => {
      state.receiptById = {};
    },
  },
});
export const { emptyReceipt, emptyInvoiceListForReceipt, emptyReceiptById } =
  receiptSlice.actions;
export default receiptSlice.reducer;
