import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  storepermission,
  storeroutepermission
} from "redux/menusPermission/menupermission";
import { IconsGenerate, PATH_ROUTE } from "layouts/dashboard/nav/utils";

const NavigateMiddleware = ({ children }) => {
  const dispatch = useDispatch();
  const { userinfo } = useSelector((state) => state.userinfo);
  useEffect(() => {
    if (userinfo?.menu?.length > 0) {
      const InfoRoute = [];
      const routeInfo = [];
      for (let menu of userinfo?.menu) {
        // CHECK IF PARENT IS AVALIABLE OR NOT
        if (Boolean(menu.parent === "")) {
          // PUSH PARENT IS NOT AVALIABLE
          InfoRoute.push({
            // name: menu.name,
            title: menu.name,
            path: PATH_ROUTE[menu.path],
            icon: IconsGenerate(menu.icon)
          });
          if (menu.add) {
            routeInfo.push({
              id: menu._id,
              path: `${PATH_ROUTE[menu.path]}/create`
            });
          }
          if (menu.edit) {
            routeInfo.push({
              id: menu._id,
              path: `${PATH_ROUTE[menu.path]}/edit`
            });
          }
          routeInfo.push({
            id: menu._id,
            path: PATH_ROUTE[menu.path],
            add: menu.add,
            view: menu.view,
            edit: menu.edit,
            remove: menu.remove
          });
        } else {
          // FIND PARENT INDEX
          const findIndex = InfoRoute.findIndex(
            (ev) => ev.name === menu.parent
          );
          if (findIndex !== -1) {
            // PUSH CHILD IF PARENT EXIST
            if(InfoRoute[findIndex].children){
              InfoRoute[findIndex].children.push({
                title: menu.name,
                path: PATH_ROUTE[menu.path]
              });
            }
            
            if (menu.add) {
              routeInfo.push({
                id: menu._id,
                path: `${PATH_ROUTE[menu.path]}/create`
              });
            }
            if (menu.edit) {
              routeInfo.push({
                id: menu._id,
                path: `${PATH_ROUTE[menu.path]}/edit`
              });
            }
            routeInfo.push({
              id: menu._id,
              path: PATH_ROUTE[menu.path],
              add: menu.add,
              view: menu.view,
              edit: menu.edit,
              remove: menu.remove
            });
          } else {
            // PARENT PARENT IF PARENT IS NOT EXIST
            InfoRoute.push({
              title: menu.parent,
              name: menu.parent,
              path: PATH_ROUTE[menu?.parent],
              icon: IconsGenerate(menu.icon),
              children: []
            });
            // AFTER PARENT CREATE NEED TO FIND INDEX
            const findIndexInner = InfoRoute.findIndex(
              (ev) => ev.name === menu.parent
            );
            // PUSH CHILD IN PARENT
            InfoRoute[findIndexInner].children.push({
              title: menu.name,
              path: PATH_ROUTE[menu.path]
            });
            if (menu.add) {
              routeInfo.push({
                id: menu._id,
                path: `${PATH_ROUTE[menu.path]}/create`
              });
            }
            if (menu.edit) {
              routeInfo.push({
                id: menu._id,
                path: `${PATH_ROUTE[menu.path]}/edit`
              });
            }
            routeInfo.push({
              id: menu.id,
              path: PATH_ROUTE[menu.path],
              add: menu.add,
              view: menu.view,
              edit: menu.edit,
              remove: menu.remove
            });
          }
        }
      }
      // SET ROUTE
      dispatch(
        storepermission([
          {
            subheader: "general",
            items: InfoRoute
          }
        ])
      );
      dispatch(storeroutepermission(routeInfo));
    } else {
      dispatch(
        storepermission([
          {
            subheader: "general",
            items: []
          }
        ])
      );
    }
  }, [userinfo]);

  return <Box>{children}</Box>;
};

export default NavigateMiddleware;
