import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllStateAsync,
  createStateAsync,
  getStateByIdAsync,
  updateStateAsync,
  deleteStateAsync,
  getAllStateWithoutPaginationAsync,
  getStateByCountryIdAsync
} from "./state.async";

const initialState = {
  stateCreateLoader: false,
  stateCreateInfo: {},

  stateListLoader: false,
  stateList: [],
  stateListInfo: {},

  stateListLoaderWithoutPagination: false,
  stateListWithoutPagination: [],
  stateListInfoWithoutPagination: {},

  stateDeleteLoading: false,
  stateDeleteInfo: {},

  stateByIdLoading: false,
  stateById: {},
  stateByIdInfo: {},

  stateUpdateLoader: false,
  stateUpdateInfo: {},

  stateByCountryLoader: false,
  getStateByCountryId: {},
  getStateByCountryIdInfo: {}
};

export const stateSlice = createSlice({
  name: "state",
  initialState, 
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(isAnyOf(createStateAsync.pending), (state) => {
      state.stateCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createStateAsync.fulfilled), (state, action) => {
      state.stateCreateLoader = false;
      state.stateCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createStateAsync.rejected), (state, action) => {
      state.stateCreateLoader = false;
    });

    //State List
    builder.addMatcher(isAnyOf(getAllStateAsync.pending), (state) => {
      state.lobListLoader = true;
      state.lobListInfo = {};
      state.lobList = [];
      state.stateListLoader=true
    });
    builder.addMatcher(isAnyOf(getAllStateAsync.fulfilled), (state, action) => {
      state.stateListLoader = false;
      state.stateListInfo = {};
      state.stateList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllStateAsync.rejected), (state, action) => {
      state.stateListLoader = false;
      state.stateListInfo = {};
      state.stateList = [];
    });

    builder.addMatcher(isAnyOf(getAllStateWithoutPaginationAsync.pending), (state) => {
      state.stateListLoaderWithoutPagination = true;
      state.stateListInfoWithoutPagination = {};
      state.stateListWithoutPagination = [];
    });
    builder.addMatcher(isAnyOf(getAllStateWithoutPaginationAsync.fulfilled), (state, action) => {
      state.stateListLoaderWithoutPagination = false;
      state.stateListInfoWithoutPagination = {};
      state.stateListWithoutPagination = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllStateWithoutPaginationAsync.rejected), (state, action) => {
      state.stateListLoaderWithoutPagination = false;
      state.stateListInfoWithoutPagination = {};
      state.stateListWithoutPagination = [];
    });

    //State Delete
    builder.addMatcher(isAnyOf(deleteStateAsync.pending), (state) => {
      state.stateDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteStateAsync.fulfilled), (state, action) => {
      state.stateDeleteLoading = false;
      state.stateDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteStateAsync.rejected), (state, action) => {
      state.stateDeleteLoading = false;
    });

    //State By Id
    builder.addMatcher(isAnyOf(getStateByIdAsync.pending), (state) => {
      state.lobByIdLoading = true;
      state.lobByIdInfo = {};
      state.lobById = {};
    });
    builder.addMatcher(
      isAnyOf(getStateByIdAsync.fulfilled),
      (state, action) => {
        state.stateByIdLoading = false;
        state.stateByIdInfo = {};
        state.stateById = action.payload?.data;
      }
    );
    builder.addMatcher(isAnyOf(getStateByIdAsync.rejected), (state, action) => {
      state.stateByIdLoading = false;
      state.stateByIdInfo = {};
      state.stateById = {};
    });

    //State Update
    builder.addMatcher(isAnyOf(updateStateAsync.pending), (state) => {
      state.stateUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateStateAsync.fulfilled), (state, action) => {
      state.stateUpdateLoader = false;
      state.stateUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateStateAsync.rejected), (state, action) => {
      state.stateUpdateLoader = false;
    });

    // get State By Country  Id
    builder.addMatcher(isAnyOf(getStateByCountryIdAsync.pending), (state) => {
      state.stateByCountryLoader = true;
      state.getStateByCountryIdInfo = {};
      state.getStateByCountryId = {};
    });
    builder.addMatcher(
      isAnyOf(getStateByCountryIdAsync.fulfilled),
      (state, action) => {
        state.stateByCountryLoader = false;
        state.getStateByCountryIdInfo = {};
        state.getStateByCountryId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getStateByCountryIdAsync.rejected),
      (state, action) => {
        state.stateByCountryLoader = false;
        state.getStateByCountryIdInfo = {};
        state.getStateByCountryId = {};
      }
    );
  },
  reducers: {
    emptyState: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyState } = stateSlice.actions;
export default stateSlice.reducer;
