import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllNoMenuListAsync = createAsyncThunk(
  "master/getAllNoMenuList",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/appendix/inventory-view?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&site=${payload?.site || ""}&chemical=${payload?.site || ""}&formDate=${payload?.formDate || ""}&toDate=${payload?.toDate || ""}`,
      [],
      toolkit
    );
  }
);