import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Box, TextField, Stack } from "@mui/material";
import Iconify from "components/iconify/Iconify";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteCustom from "components/AutoCompleteCustom/AutoCompleteCustom";
import { _followType, _initial, _validate } from "./util";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { createFollowUpAsync } from "redux/slices/followup/followup.async";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import { emptyFollowUp } from "redux/slices/followup/followup.slice";
import { getAllUserAsync } from "redux/user/user.async";
import { getAllFollowUpAsync } from "redux/slices/followup/followup.async";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function CustomizedDialogs(props) {
  const { leadFollowupId } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  

  leadFollowupId(id);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { followUpCreateLoader, followUpCreateInfo = [] } = useSelector(
    (state) => state?.followUp
  );
  const { userLoader, users = [] } = useSelector((state) => state?.user);
  const { followUpListLoader, followUpList = [] } = useSelector(
    (state) => state?.followUp
  );
  const onSubmit = async (values) => {
    const payload = {
      leadId: id,
      type: values?.type?.value,
      assignTo: values?.assignTo.value,
      followupResponse: values?.followupResponse,
      nextFollowupDate: values?.nextFollowupDate,
      nextFollowupTime: values?.nextFollowupTime
    };
    dispatch(createFollowUpAsync(payload));
  };

  const formik = useFormik({
    initialValues: _initial,
    onSubmit,
    validationSchema: _validate
  });

  useEffect(() => {
    dispatch(getAllUserAsync({}));
  }, []);
  
  useEffect(() => {
    if (followUpCreateInfo?.success) {
      toast.success(followUpCreateInfo?.message, toastoptions);
      dispatch(emptyFollowUp());
      formik.resetForm();
      setOpen(false);
      dispatch(getAllFollowUpAsync({page: 1, limit: 10, leadId:id}));
    }
  }, [followUpCreateInfo]);

  const resetHandler = () => {
    formik.resetForm();
  };

  return (
    <>
      <Stack alignItems="flex-end" sx={{ mb: 2 }}>
        <Button variant="contained" onClick={handleClickOpen}>
          <Iconify icon="eva:plus-fill" /> Add Follow Up
        </Button>
      </Stack>

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ maxWidth: "900px !important", margin: "0px auto !important" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          divider
        >
          Add Follow Up
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ paddingBottom: "0px !important" }} divider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  rowGap={2}
                  columnGap={4}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(2, 1fr)"
                  }}
                  sx={{ pt: 2 }}
                >
                  <AutoCompleteCustom
                    name="type"
                    options={_followType}
                    value={formik.values.type}
                    onChange={(event, value) =>
                      formik.setFieldValue("type", value)
                    }
                    error={formik.touched.type && formik.errors.type}
                    label="Select Type"
                  />

                  <TextField
                    name="nextFollowupDate"
                    type="datetime-local"
                    label="Follow Up Date"
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                      max: "9999-12-31T23:59"
                    }}
                    InputLabelProps={{ shrink: "false" }}
                    {...formik.getFieldProps("nextFollowupDate")}
                    onChange={(e) => {
                      formik.setFieldValue("nextFollowupDate", e.target.value);
                    }}
                    error={
                      formik.touched.nextFollowupDate &&
                      formik.errors.nextFollowupDate
                    }
                  />
                  <TextField
                    name="nextFollowupTime"
                    type="datetime-local"
                    label=" Next Follow Up Time"
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                      max: "9999-12-31T23:59"
                    }}
                    InputLabelProps={{ shrink: "false" }}
                    {...formik.getFieldProps("nextFollowupTime")}
                    onChange={(e) => {
                      formik.setFieldValue("nextFollowupTime", e.target.value);
                    }}
                    error={
                      formik.touched.nextFollowupTime &&
                      formik.errors.nextFollowupTime
                    }
                  />
                  <AutoCompleteCustom
                    name="assignTo"
                    loading={userLoader}
                    options={_.map(users?.data?.users, (ev) => {
                      return {
                        label: ev.firstName + " " + ev.lastName,
                        value: ev._id
                      };
                    })}
                    value={formik.values.assignTo}
                    onChange={(event, value) => {
                      formik.setFieldValue("assignTo", value);
                    }}
                    label="Assign To"
                    error={formik.touched.assignTo && formik.errors.assignTo}
                  />
                </Box>
                <Box
                  rowGap={2}
                  columnGap={4}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)"
                  }}
                  sx={{ py: 2 }}
                >
                  <TextField
                    type="textarea"
                    multiline
                    rows={3}
                    maxRows={5}
                    name="followupResponse"
                    label="Follow Up Response"
                    {...formik.getFieldProps("followupResponse")}
                    onChange={(e) => {
                      formik.setFieldValue("followupResponse", e.target.value);
                    }}
                    error={
                      formik.touched.followupResponse &&
                      formik.errors.followupResponse
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                pt: "0px !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end"
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={followUpCreateLoader}
              >
                Save
              </LoadingButton>
              <Button autoFocus variant="contained" onClick={resetHandler}>
                Reset Form
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </BootstrapDialog>
    </>
  );
}
