import { Navigate, useRoutes } from 'react-router-dom';
import GuestGuard from 'auth/GuestGuard';
import CompactLayout from 'layouts/compact';
import DashboardLayout from 'layouts/dashboard';
import {
  ChangePassword,
  Dashboard,
  Login,
  NewPassword,
  Page404,
  ResetPassword,
  SiteSettings,
  IndivisualSetting,
  Roles,
  Permission,
  State,
  CreateState,
  Country,
  City,
  Business,
  CreateCity,
  Measurement,
  CreateMeasurement,
  Vendor,
  CreateVendor,
  BusinessAdd,
  Sites,
  Vessels,
  Activities,
  CreateActivities,
  VendorSku,
  Inventory,
  CreateInventory,
  Document,
  CreateDocument,
  LeadSource,
  CreateSites,
  Service,
  ServiceAdd,
  CreateVendorsku,
  CreateVessels,
  CreateAccreditation,
  Accreditation,
  CreateCredential,
  Credential,
  User,
  CreateUser,
  Lead,
  Quotations,
  LeadView,
  Purchase,
  CreatePurchase,
  Contract,
  CreateContract,
  Inbound,
  CreateInbound,
  LeadCreate,
  QuotationsCreate,
  CreateSource,
  Customers,
  Sales,
  CreateSales,
  Roster,
  PincodeCreate,
  Pincode,
  Currency,
  CurrencyAdd,
  Invoice,
  Receipt,
  CreditNote,
  MainServices,
  Jobs,
  Certificates,
  CreateCertificate,
  InventoryAllocations,
  CreateInventoryAllocations,
  QuotaionsView,
  CreateJob,
  Feedback,
  CreateInvoice,
  PortList,
  CreatePort,
  Expense,
  AssignPermission,
  AttendanceList,
  ViewActivitiesList,
  SendSlip,
  AppendixList,
  Pest,
  CreatePest,
  LeadConversion,
  QuotationConversion,
  ContractConversion,
  RevenueNumbers,
  Profitability,
  CreateDuplicate,
  CollectionPercentage,
  TotalCustomerCount,
  ManPowerUtilization,
  CustomersRevenue,
  CustomersPayables,
  CustomersDelaying,
  VerifyCode,
  CustomerEdit,
  GetAllReceipt,
  UpdateReciept,
  JobsSchedule,
  InventoryNoMenuList,
  CreateReceipt,
  GstReport,
  OutstandingReport,
  RenewalSystemQuotations
} from './elements';
import AuthMiddleware from 'layouts/Middleware/AuthMiddleware';
import RoutePermissionMiddleware from 'layouts/Middleware/RoutePermissionMiddleware';
import NavigateMiddleware from 'layouts/Middleware/NavigateMiddleware';
import CountryCreate from 'pages/Master/Country/CreateCountry';
import ContainerReport from 'pages/Mis/ContainerReport/ContainerReport';
// import CreateSource from "pages/Master/LeadSource/CreateSource";

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'new-password', element: <NewPassword /> },

            { path: 'verify', element: <VerifyCode /> },
          ],
        },
      ],
    },
    {
      path: 'app',
      element: (
        // <AuthMiddleware>
        //   <DashboardLayout />
        // </AuthMiddleware>
        <AuthMiddleware>
          <NavigateMiddleware>
            <RoutePermissionMiddleware>
              <DashboardLayout />
            </RoutePermissionMiddleware>
          </NavigateMiddleware>
        </AuthMiddleware>
      ),
      children: [
        { path: 'dashboard', element: <Dashboard /> },

        // Country
        { path: 'master/country', element: <Country /> },
        { path: 'master/country/createcountry', element: <CountryCreate /> },
        { path: 'master/country/edit/:id', element: <CountryCreate /> },
        { path: 'master/country/view/:id', element: <CountryCreate /> },

        // State
        { path: 'master/state', element: <State /> },
        { path: 'master/state/createstate', element: <CreateState /> },
        { path: 'master/state/edit/:id', element: <CreateState /> },
        { path: 'master/state/view/:id', element: <CreateState /> },

        // City
        { path: 'master/city', element: <City /> },
        { path: 'master/city/createcity', element: <CreateCity /> },
        { path: 'master/city/edit/:id', element: <CreateCity /> },
        { path: 'master/city/view/:id', element: <CreateCity /> },

        // Line of business
        { path: 'master/business', element: <Business /> },
        { path: 'master/business/createbusiness', element: <BusinessAdd /> },
        { path: 'master/business/edit/:id', element: <BusinessAdd /> },
        { path: 'master/business/view/:id', element: <BusinessAdd /> },

        // unit of measurement
        { path: 'master/measurement', element: <Measurement /> },
        {
          path: 'master/measurement/createmeasurement',
          element: <CreateMeasurement />,
        },
        { path: 'master/measurement/edit/:id', element: <CreateMeasurement /> },
        { path: 'master/measurement/view/:id', element: <CreateMeasurement /> },

        // vendor
        { path: 'master/vendor', element: <Vendor /> },
        { path: 'master/vendor/createvendor', element: <CreateVendor /> },
        { path: 'master/vendor/edit/:id', element: <CreateVendor /> },
        { path: 'master/vendor/view/:id', element: <CreateVendor /> },

        // Service
        { path: 'master/service', element: <Service /> },
        { path: 'master/service/createservice', element: <ServiceAdd /> },
        { path: 'master/service/edit/:id', element: <ServiceAdd /> },
        { path: 'master/service/view/:id', element: <ServiceAdd /> },
        { path: 'jobs-schedule/:id', element: <JobsSchedule /> },

        // Sites
        { path: 'master/sites', element: <Sites /> },
        { path: 'master/sites/createsites', element: <CreateSites /> },
        { path: 'master/sites/edit/:id', element: <CreateSites /> },
        { path: 'master/sites/view/:id', element: <CreateSites /> },

        // Vessels
        { path: 'master/Vessels', element: <Vessels /> },
        { path: 'master/vessels/createvessels', element: <CreateVessels /> },
        { path: 'master/vessels/edit/:id', element: <CreateVessels /> },
        { path: 'master/vessels/view/:id', element: <CreateVessels /> },

        // Activities
        { path: 'master/activities', element: <Activities /> },
        {
          path: 'master/activities/createactivities',
          element: <CreateActivities />,
        },
        { path: 'master/activities/edit/:id', element: <CreateActivities /> },
        { path: 'master/activities/view/:id', element: <CreateActivities /> },

        // VendorSku
        { path: 'master/vendorsku', element: <VendorSku /> },
        {
          path: 'master/vendorsku/createvendorsku',
          element: <CreateVendorsku />,
        },
        { path: 'master/vendorsku/edit/:id', element: <CreateVendorsku /> },
        { path: 'master/vendorsku/view/:id', element: <CreateVendorsku /> },

        // Inventory
        { path: 'master/inventory', element: <Inventory /> },
        {
          path: 'master/inventory/createinventory',
          element: <CreateInventory />,
        },
        { path: 'master/inventory/edit/:id', element: <CreateInventory /> },
        { path: 'master/inventory/view/:id', element: <CreateInventory /> },

        // Document
        { path: 'master/document', element: <Document /> },
        { path: 'master/document/createdocument', element: <CreateDocument /> },
        { path: 'master/document/edit/:id', element: <CreateDocument /> },
        { path: 'master/document/view/:id', element: <CreateDocument /> },

        // LeadSource
        { path: 'master/leadSource', element: <LeadSource /> },
        { path: 'master/leadSource/createsource', element: <CreateSource /> },
        { path: 'master/leadSource/edit/:id', element: <CreateSource /> },
        { path: 'master/leadSource/view/:id', element: <CreateSource /> },

        // vendor
        { path: 'master/vendor', element: <Vendor /> },
        { path: 'master/vendor/createvendor', element: <CreateVendor /> },

        // Accreditation
        { path: 'master/accreditation', element: <Accreditation /> },
        {
          path: 'master/accreditation/createaccreditation',
          element: <CreateAccreditation />,
        },
        {
          path: 'master/accreditation/edit/:id',
          element: <CreateAccreditation />,
        },
        {
          path: 'master/accreditation/view/:id',
          element: <CreateAccreditation />,
        },
        // GST Credential
        { path: 'master/credential', element: <Credential /> },
        {
          path: 'master/credential/createcredential',
          element: <CreateCredential />,
        },
        {
          path: 'master/credential/edit/:id',
          element: <CreateCredential />,
        },
        {
          path: 'master/credential/view/:id',
          element: <CreateCredential />,
        },

        // pincode
        { path: 'master/pincode', element: <Pincode /> },
        { path: 'master/pincode/createpincode', element: <PincodeCreate /> },
        { path: 'master/pincode/edit/:id', element: <PincodeCreate /> },
        { path: 'master/pincode/view/:id', element: <PincodeCreate /> },

        // Currency
        { path: 'master/currency', element: <Currency /> },
        { path: 'master/currency/createcurrency', element: <CurrencyAdd /> },
        { path: 'master/currency/edit/:id', element: <CurrencyAdd /> },
        { path: 'master/currency/view/:id', element: <CurrencyAdd /> },

        // Pest
        { path: 'master/pest', element: <Pest /> },
        { path: 'master/pest/createpest', element: <CreatePest /> },
        { path: 'master/pest/edit/:id', element: <CreatePest /> },
        { path: 'master/pest/view/:id', element: <CreatePest /> },

        //

        // CHANGE PASSWORD
        { path: 'change-password', element: <ChangePassword /> },
        // GENERAL SETTINGS
        // {
        //   path: "general-setting/instruction-setting",
        //   element: <Instructions />,
        // },

        { path: 'general-setting/site-setting', element: <SiteSettings /> },
        {
          path: 'general-setting/indivisual-setting',
          element: <IndivisualSetting />,
        },

        //new
        { path: 'user-management/roles', element: <Roles /> },
        {
          path: 'user-management/roles/permission/:id',
          element: <Permission />,
        },

        { path: 'user-management/user-list', element: <User /> },
        { path: 'user-management/user/create', element: <CreateUser /> },
        { path: 'user-management/user/edit/:id', element: <CreateUser /> },
        { path: 'user-management/user/view/:id', element: <CreateUser /> },
        {
          path: 'user-management/user/role-permission/create',
          element: <AssignPermission />,
        },
        {
          path: 'user-management/user/role-permission/permission/:id',
          element: <AssignPermission />,
        },

        { path: 'lead/', element: <Lead /> },
        { path: 'lead/create', element: <LeadCreate /> },
        { path: 'lead/leadview/:id', element: <LeadView /> },
        { path: 'lead/edit/:id', element: <LeadCreate /> },

        // quotation
        { path: 'quotation/', element: <Quotations /> },
        { path: 'quotation/create', element: <QuotationsCreate /> },
        { path: 'quotation/edit/:id', element: <QuotationsCreate /> },
        // { path: "quotation/duplicate", element: <CreateDuplicate /> },
        { path: 'quotation/duplicate/:id', element: <CreateDuplicate /> },

        { path: 'customers', element: <Customers /> },

        // purchase
        { path: 'purchasemenu/purchase', element: <Purchase /> },
        {
          path: 'purchasemenu/purchase/createpurchase',
          element: <CreatePurchase />,
        },
        { path: 'purchasemenu/purchase/view/:id', element: <CreatePurchase /> },
        { path: 'purchasemenu/purchase/edit/:id', element: <CreatePurchase /> },

        //expense
        { path: 'expense', element: <Expense /> },

        // sales
        { path: 'purchasemenu/sales', element: <Sales /> },
        { path: 'purchasemenu/sales/createsales', element: <CreateSales /> },
        { path: 'purchasemenu/sales/view/:id', element: <CreateSales /> },
        { path: 'purchasemenu/sales/edit/:id', element: <CreateSales /> },

        { path: 'contract', element: <Contract /> },
        { path: 'contract/createcontract', element: <CreateContract /> },
        { path: 'contract/edit/:id', element: <CreateContract /> },
        { path: 'contract/view/:id', element: <CreateContract /> },

        //Attendance
        { path: 'attendance', element: <AttendanceList /> },

        // Inbound
        { path: 'purchasemenu/inbound', element: <Inbound /> },
        {
          path: 'purchasemenu/inbound/createinbound',
          element: <CreateInbound />,
        },
        { path: 'purchasemenu/inbound/edit/:id', element: <CreateInbound /> },
        { path: 'purchasemenu/inbound/view/:id', element: <CreateInbound /> },

        // Roster
        { path: 'roster', element: <Roster /> },

        { path: 'appendix/inventory-view', element: <InventoryNoMenuList /> },

        // invoice
        { path: 'invoice/invoices', element: <Invoice /> },
        { path: 'invoice/Createinvoice', element: <CreateInvoice /> },
        { path: 'invoice/edit/:id', element: <CreateInvoice /> },
        { path: 'invoice/view/:id', element: <CreateInvoice /> },
        { path: 'get-all-receipt/:id', element: <GetAllReceipt /> },
        { path: 'edit-receipt/:id', element: <UpdateReciept /> },

        // receipt
        { path: 'invoice/receipt', element: <Receipt /> },
        { path: 'invoice/receipt/create', element: <CreateReceipt /> },
        { path: 'invoice/receipt/edit/:id', element: <CreateReceipt /> },

        // credit note
        { path: 'invoice/creditnote', element: <CreditNote /> },

        //Services
        { path: 'services/mainservice', element: <MainServices /> },
        { path: 'services/mainservice/send-slip', element: <SendSlip /> },
        //Jobs
        { path: 'services/jobs', element: <Jobs /> },
        { path: 'services/jobs/createjobs', element: <CreateJob /> },
        { path: 'services/jobs/edit', element: <CreateJob /> },
        { path: 'services/jobs/feedback', element: <Feedback /> },
        {
          path: 'services/jobs/viewactivities',
          element: <ViewActivitiesList />,
        },

        { path: 'services/certificates', element: <Certificates /> },
        {
          path: 'services/certificates/createcertificate',
          element: <CreateCertificate />,
        },
        {
          path: 'services/certificates/editcertificate/:id',
          element: <CreateCertificate />,
        },
        {
          path: 'services/certificates/customeredit/:id',
          element: <CustomerEdit />,
        },

        //Operations
        {
          path: 'operations/inventoryallocations',
          element: <InventoryAllocations />,
        },
        {
          path: 'operations/inventoryallocations/createinventoryallocations',
          element: <CreateInventoryAllocations />,
        },
        {
          path: 'operations/inventoryallocations/edit/:id',
          element: <CreateInventoryAllocations />,
        },
        {
          path: 'operations/inventoryallocations/view/:id',
          element: <CreateInventoryAllocations />,
        },

        {
          path: 'renewalsystem',
          element: <RenewalSystemQuotations />,
        },

        // Goverment Document
        { path: 'governmentdocument/appendix', element: <AppendixList /> },

        { path: 'invoice/edit/:id', element: <CreateInvoice /> },
        // QuotaionsView
        { path: 'quotationsview/:id', element: <QuotaionsView /> },

        // Port
        { path: 'master/port', element: <PortList /> },
        { path: 'master/port/create-port', element: <CreatePort /> },
        { path: 'master/port/edit/:id', element: <CreatePort /> },
        { path: 'master/port/view/:id', element: <CreatePort /> },

        //   Mis
        { path: 'mis/leadconversion', element: <LeadConversion /> },
        { path: 'mis/QuotationConversion', element: <QuotationConversion /> },
        { path: 'mis/contractconversion', element: <ContractConversion /> },
        { path: 'mis/profitability', element: <Profitability /> },
        { path: 'mis/revenuenumbers', element: <RevenueNumbers /> },
        { path: 'mis/collectionpercentage', element: <CollectionPercentage /> },
        { path: 'mis/totalcustomerCount', element: <TotalCustomerCount /> },
        { path: 'mis/manPowerutilization', element: <ManPowerUtilization /> },
        { path: 'mis/customersrevenue', element: <CustomersRevenue /> },
        { path: 'mis/customerspayables', element: <CustomersPayables /> },
        { path: 'mis/customersdelaying', element: <CustomersDelaying /> },
        { path: 'mis/gstreports', element: <GstReport /> },
        { path: 'mis/outstandingreport', element: <OutstandingReport /> },
        { path: 'mis/containerreport', element: <ContainerReport /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
