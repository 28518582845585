import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

//  getAllRosterAsync
export const getAllRosterAsync = createAsyncThunk(
  "roster/getAllRoster",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/roster/get-roster?user`,
      payload,
      toolkit
    );
  }
);

export const getAllRosterWithoutPaginationAsync = createAsyncThunk(
  "roster/getAllRosterWithoutPaginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/roster/list-jobNo`, [], toolkit);
  }
);

// getUserByRoleAsync
export const getUserByRoleAsync = createAsyncThunk(
  "roster/getUserByRoleAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/user/get-user-by-role?role=${payload?._id}`,
      [],
      toolkit
    );
  }
);

export const getAllListUnassignedJobsAsync = createAsyncThunk(
  "roster/getAllListUnassignedJobsAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/list-jobs?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&user=${payload?.user || ""}&site=${payload?.site ||""}&job=${payload?.job || ""}&customer=${payload?.customer || ""}`,
      [],
      toolkit
    );
  }
);

export const createRosterAsync = createAsyncThunk(
  "roster/createRosterAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/roster/create-roster`, payload, toolkit);
  }
);

export const getRosterByIdAsync = createAsyncThunk(
  "roster/getRosterByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/roster/get-roster-id/${payload}`, payload, toolkit);
  }
);

export const updateRoasterByIdAsync = createAsyncThunk(
  "roster/update-roster",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/roster/update-roster/${payload?.jobId}`, payload, toolkit);
  }
);
export const deleteRoasterByIdAsync = createAsyncThunk(
  "roster/delete-roster",
  async (payload, toolkit) => {
    return await AxiosClient("DELETE", `/roster/delete-roster/${payload?.id}`, payload, toolkit);
  }
);
