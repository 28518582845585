import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAllMenusAsync,getAllRoutesAsync } from "./menus.async";

const initialState = {
  menuLoader: false,
  allMenus: [],
  routeLoader: false,
  allRoutes: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllMenusAsync.pending), (state) => {
      state.menuLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllMenusAsync.fulfilled),
      (state, action) => {
        state.menuLoader = false;
        state.allMenus = action?.payload?.data?.menus ?? [];
      }
    );
    builder.addMatcher(isAnyOf(getAllMenusAsync.rejected), (state, action) => {
      state.menuLoader = false;
    });
    builder.addMatcher(isAnyOf(getAllRoutesAsync.pending), (state) => {
      state.routeLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllRoutesAsync.fulfilled),
      (state, action) => {
        state.routeLoader = false;
        state.allRoutes = action.payload.data.data;
      }
    );
    builder.addMatcher(isAnyOf(getAllRoutesAsync.rejected), (state, action) => {
      state.routeLoader = false;
    });
  },
  reducers: {
    emptymenus: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptymenus } = menuSlice.actions;

export default menuSlice.reducer;
