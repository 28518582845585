import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";


export const generateEWayBill = createAsyncThunk(
  "/generateEWayBill",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/gst/generate-e-way-bill/${payload}`,
      [],
      toolkit
    );
  }
);

export const cancelEWayBill = createAsyncThunk(
  "/cancelEWayBill",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/gst/cancel-e-way-bill`,
      payload,
      toolkit
    );
  }
);

export const generateEInvoice = createAsyncThunk(
  "/generateEInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/gst/generate-e-invoice/${payload}`,
      [],
      toolkit
    );
  }
);

export const cancelEInvoice = createAsyncThunk(
  "/cancelEInvoice",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/gst/cancel-e-invoice`, payload, toolkit);
  }
);

//download Gst Report Csv File
export const gstReportExcelDownloadAsync = ({page,limit,customer, site, toDate, fromDate}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/mis/download-gst-report?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page}&limit=${limit}&customer=${customer}&fromDate=${fromDate}&toDate=${toDate}&site=${site}`,
    "_parent"
  );
};
