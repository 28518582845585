import Iconify from "components/iconify/Iconify";
import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { capitalize } from "lodash";
import { useSettingsContext } from "components/settings";

import LeadContract from "./LeadContract";
import LeadFollowUp from "./LeadFollowUp";
import LeadQuotation from "./LeadQuotation";

// tab

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// profile menu

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { PATH_DASHBOARD } from "routes/paths";

import Notes from "./Notes";

// api
import { getLeadByIdAsync } from "redux/slices/lead/lead.async";
import { UploadAvatar } from "components/upload";
import { toast } from "react-hot-toast";
import { toastoptions } from "utils/toastoptions";
import axios from "axios";
import { isJson } from "utils/isJson";
const TABS = [
  {
    value: "LeadContract",
    label: "LeadContract",
    icon: <Iconify icon="ic:round-receipt" />,
    component: <LeadFollowUp />
  },
  {
    value: "LeadFollowUp",
    label: "LeadFollowUp",
    icon: <Iconify icon="ic:round-account-box" />,
    component: <LeadQuotation />
  },
  {
    value: "LeadQuotation",
    label: "LeadQuotation",
    icon: <Iconify icon="ic:round-receipt" />,
    component: <LeadContract />
  }
];

const View = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // tab
  const [value, setValue] = React.useState("1");
  // const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { themeStretch } = useSettingsContext();

  // profile menu
  const [selectedFile, setSelectedFile] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { leadById = {}, leadByIdLoading } = useSelector(
    (state) => state?.lead
  );

  useEffect(() => {
    if (id) {
      dispatch(getLeadByIdAsync(id));
    }
  }, [id]);

  const handleDrop = async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file)
      });

      if (file) {
        setSelectedFile(newFile);
      }

      const formData = new FormData();
      formData.append("file", file);

      const authToken = isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null;

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/quotation/upload-lead-avatar/${leadById?.leadData?._id}?contact=${leadById?.leadData?.contactDetails[0]?._id}`,
        formData,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (response.data.success) {
        dispatch(getLeadByIdAsync(id));
      }

      setSelectedFile(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // SUCCESS
    if (selectedFile?.success) {
      toast.success(selectedFile.message, toastoptions);
      setSelectedFile(selectedFile?.data?.contactDetails[0]?.avatar);
    }
  }, [selectedFile]);
  useEffect(() => {
    // SUCCESS
    if (id && leadById) {
      setSelectedFile(leadById?.leadData?.contactDetails[0]?.avatar);
    }
  }, [id, leadById]);

  const filteredDataLocatonIsBillingAddressTrue =
    leadById?.leadData?.locationDetails.filter(
      (item) => item.isBillingAddress === true
    );

  return (
    <>
      <Container maxWidth={themeStretch ? false : "lg"}>
        {/* card 1  */}
        <Card
          sx={{
            mb: 3
          }}
        >
          <Grid container spacing={{ md: 3 }}>
            {/* profile */}
            <Grid item xs={12} md={5}>
              <Box sx={{ p: 3, textAlign: "center", marginBottom: "-15px" }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "space-around",
                      marginTop: "-30px",
                      position: ""
                    }}
                  >
                    <Tooltip sx={{ marginBottom: "10px" }} title="Change">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Box sx={{ mb: 2 }}>
                          <UploadAvatar
                            name="file"
                            accept={{
                              "image/*": []
                            }}
                            file={selectedFile}
                            onDrop={handleDrop}
                          />
                        </Box>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Box sx={{ ml: 4 }}>
                  <Typography variant="body3" sx={{ fontWeight: "600" }}>
                    {capitalize(
                      leadById?.leadData?.contactDetails?.[0]?.contactPerson
                    )}
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography variant="body2">
                    {capitalize(
                      leadById?.leadData?.contactDetails?.[0]?.designation
                    )}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row"
                  }}
                >
                  <Box>
                    <Button
                      disabled={leadById?.leadData?.status !== "qualified"}
                      variant="contained"
                      sx={{ mr: 1 }}
                      onClick={() =>
                        navigate(
                          `${PATH_DASHBOARD.createQuotation}?id=${leadById?.leadData?._id}`
                        )
                      }
                    >
                      Create Quotation
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      disabled={leadById?.leadData?.status !== "qualified"}
                      variant="contained"
                      sx={{ mr: 1 }}
                      onClick={() =>
                        navigate(
                          `${PATH_DASHBOARD.createcontract}?id=${leadById?.leadData?._id}`
                        )
                      }
                    >
                      Create Contract
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row"
                  }}
                >
                  {/* <Box> */}
                  {/* Required Later */}
                  {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1, mt: 1 }}
                  >
                    Create Invoice
                  </Button> */}
                  {/* </Box>   */}

                  <Notes />
                </Box>
                {/* </Box> */}
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <Box sx={{ display: "flex" }}>
                {/* contact */}
                <Box>
                  <Box
                    sx={{
                      display: "flex !important",
                      alignItem: "center !important",
                      marginTop: "15px !important"
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Iconify icon="mdi:contact" />
                      </a>
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      <a
                        href=""
                        style={{
                          textDecoration: "none",
                          color: "black",
                          hover: "blue"
                        }}
                      >
                        {/* www.mywebsite.com{" "} */}
                        {leadById?.leadData?.contactDetails[0]?.contactPerson}
                      </a>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ mr: 1 }}>
                      {" "}
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {" "}
                        <Iconify icon="ic:outline-email" />
                      </a>
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {" "}
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {leadById?.leadData?.contactDetails?.[0]?.email}
                      </a>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ mr: 1 }}>
                      {" "}
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Iconify icon="material-symbols:call-outline-rounded" />
                      </a>
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {leadById?.leadData?.contactDetails?.[0]?.mobile}
                      </a>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ mr: 1 }}>
                      {" "}
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {" "}
                        <Iconify icon="material-symbols:call-outline-rounded" />
                      </a>
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {" "}
                      <a
                        href=""
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {leadById?.leadData?.contactDetails?.[0]?.landLine}
                      </a>
                    </Box>
                  </Box>
                </Box>

                {/* location */}
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "0px !important",
                      // paddingRight: "50px",
                      paddingLeft: "80px",
                      maxWidth: "350px"
                    }}
                  >
                    <Box sx={{ mr: 1, marginTop: "20px" }}>
                      <Iconify icon="material-symbols:location-on-outline-rounded" />{" "}
                    </Box>
                    <Box>
                      <p sx={{ fontSize: "14px" }}>
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.locality
                        )}
                        ,
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.address
                        )}
                        ,
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]
                            ?.locationName
                        )}
                        ,
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.city
                            ?.cityName
                        )}
                        ,<br />
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.state
                            ?.stateName
                        )}
                        ,
                        {capitalize(
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.country
                            ?.countryName
                        )}
                        ,(
                        {
                          filteredDataLocatonIsBillingAddressTrue?.[0]?.pincode
                            ?.pincode
                        }
                        )
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* tabs main */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    alignItems: "flex-end",
                    marginTop: "120px",
                    display: "flex !important"
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab sx={{ ml: "5" }} label="Follow up" value="1" />
                        <Tab sx={{ ml: "5" }} label="Quotation " value="2" />
                        <Tab sx={{ ml: "5" }} label="Contract" value="3" />
                      </TabList>
                    </Box>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>

            {/* tabs */}
          </Grid>
        </Card>

        <Grid container spacing={{ md: 3 }}>
          {/* card */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={{ md: 3 }}>
              {/* card A */}
              <Grid item xs={12}>
                <Card
                  sx={{
                    p: 3
                  }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      Lead Details&nbsp;&nbsp;
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "600", marginTop: "20px" }}
                    >
                      {capitalize(leadById?.leadData?.businessName)}
                      <br />
                    </Typography>
                  </Box>

                  <h5>
                    Mobile <br />
                    {leadById?.leadData?.contactDetails?.[0]?.mobile}
                    <br />
                    {leadById?.leadData?.contactDetails?.[0]?.landLine}
                    &nbsp;&nbsp;
                  </h5>
                  <h5>
                    {" "}
                    Email <br />
                    <a href="" style={{ textDecoration: "none" }}>
                      {capitalize(
                        leadById?.leadData?.contactDetails?.[0]?.email
                      )}
                    </a>
                  </h5>
                  <h5>
                    Lead Type <br />
                    {capitalize(leadById?.leadData?.type)}
                  </h5>
                  <h5>
                    Service <br />
                    {capitalize(leadById?.leadData?.services?.[0]?.serviceName)}
                  </h5>
                  <h5>
                    GST Exempted <br />
                    {capitalize(
                      leadById?.leadData?.gstExempted === true ? "Yes" : "No"
                    )}
                  </h5>
                  <h5>
                    Notification Number/ LUT <br />
                    {leadById?.leadData?.notificationNumber}
                  </h5>
                  <h5>
                    Proof of Exemption <br />
                    {capitalize(
                      leadById?.leadData?.uploadProofOfExemption
                        ? "Attached"
                        : "Not Attached"
                    )}
                    {leadById?.leadData?.uploadProofOfExemption && (
                      <>
                        &nbsp;
                        <a
                          href={`${leadById?.leadData?.uploadProofOfExemption.replace(
                            "//",
                            "/"
                          )}`}
                        >
                          <Button size="small" variant="contained" sx={{ml:2}} >
                            Download
                          </Button>
                        </a>
                      </>
                    )}
                  </h5>

                  <h5>
                    GST Number
                    <br />
                    {leadById?.leadData?.gstNumber} <br />
                  </h5>
                  <h5>
                    GST Document <br />
                    {capitalize(
                      leadById?.leadData?.gstDocument
                        ? "Attached"
                        : "Not Attached"
                    )}
                    {leadById?.leadData?.gstDocument && (
                      <>
                        &nbsp;
                        <a
                          href={`${leadById?.leadData?.gstDocument.replace(
                            "//",
                            "/"
                          )}`}
                        >
                          <Button
                            size="small"
                            sx={{ ml: 2 }}
                            variant="contained"
                        
                          >
                            Download
                          </Button>
                        </a>
                      </>
                    )}
                  </h5>
                  <h5>
                    Lead Source <br />
                    {capitalize(leadById?.leadData?.leadSource?.name)}
                  </h5>
                  <p></p>
                </Card>
              </Grid>

              {/* card B */}
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      Contact Details&nbsp;&nbsp;
                    </Typography>
                  </Box>
                  <h5>
                    {capitalize(
                      leadById?.leadData?.contactDetails?.[0]?.contactPerson
                    )}
                  </h5>
                  <p>
                    {capitalize(
                      leadById?.leadData?.contactDetails?.[0]?.designation
                    )}
                    ,
                    {capitalize(
                      leadById?.leadData?.contactDetails?.[0]?.locality
                    )}
                  </p>
                  <h5>
                    Mobile <br />
                    {leadById?.leadData?.contactDetails?.[0]?.mobile}
                  </h5>

                  <h5>
                    Email <br />
                    <a href="" style={{ textDecoration: "none" }}>
                      {leadById?.leadData?.contactDetails?.[0]?.email} <br />
                    </a>
                  </h5>

                  <h5>
                    Landline <br />
                    {leadById?.leadData?.contactDetails?.[0]?.landLine}
                    <br />
                  </h5>
                  {leadById?.leadData?.contactDetails?.[1] && (
                    <>
                      <hr />
                      <h5>
                        {capitalize(
                          leadById?.leadData?.contactDetails[1].contactPerson ||
                            ""
                        )}
                      </h5>
                      <p>
                        {capitalize(
                          leadById?.leadData?.contactDetails[1].designation ||
                            ""
                        )}
                        ,
                        {capitalize(
                          leadById?.leadData?.contactDetails[1].locality || ""
                        )}
                      </p>
                      <h5>
                        Mobile <br />
                        {leadById?.leadData?.contactDetails[1].mobile || ""}
                      </h5>
                      <h5>
                        Email <br />
                        <a href="" style={{ textDecoration: "none" }}>
                          {leadById?.leadData?.contactDetails[1].email}
                          <br />
                        </a>
                      </h5>
                      <h5>
                        Landline <br />
                        {leadById?.leadData?.contactDetails[1].landLine || ""}
                        <br />
                      </h5>
                    </>
                  )}
                </Card>
              </Grid>

              {/* card C */}
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <h5>Location Details</h5>
                  <Typography>
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.address || ""
                    )}
                    ,
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.locationName ||
                        ""
                    )}
                    ,
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.locality || ""
                    )}
                    <br />
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.city
                        ?.cityName || ""
                    )}
                    ,
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.state
                        ?.stateName || ""
                    )}
                    ,
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.country
                        ?.countryName || ""
                    )}
                    <br />(
                    {capitalize(
                      leadById?.leadData?.locationDetails?.[0]?.pincode
                        ?.pincode || ""
                    )}
                    )
                  </Typography>
                  {leadById?.leadData?.contactDetails?.[1] && (
                    <>
                      <hr />
                      <Typography>
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.address ||
                            ""
                        )}
                        ,
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]
                            ?.locationName || ""
                        )}
                        ,
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.locality ||
                            ""
                        )}
                        <br />
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.city
                            ?.cityName || ""
                        )}
                        ,
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.state
                            ?.stateName || ""
                        )}
                        ,
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.country
                            ?.countryName || ""
                        )}
                        <br />(
                        {capitalize(
                          leadById?.leadData?.locationDetails?.[1]?.pincode
                            ?.pincode || ""
                        )}
                        )
                      </Typography>
                    </>
                  )}

                  <>
                    <hr />
                    <h5>Billing Address</h5>
                    <Typography>
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]?.address ||
                          ""
                      )}
                      ,
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]
                          ?.locationName || ""
                      )}
                      ,
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]
                          ?.locality || ""
                      )}
                      <br />
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]?.city
                          ?.cityName || ""
                      )}
                      ,
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]?.state
                          ?.stateName || ""
                      )}
                      ,
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]?.country
                          ?.countryName || ""
                      )}
                      <br />(
                      {capitalize(
                        filteredDataLocatonIsBillingAddressTrue?.[0]?.pincode
                          ?.pincode || ""
                      )}
                      )
                    </Typography>
                  </>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* tble */}
          <Grid item xs={12} md={8} sx={{ mb: { xs: 3.2, mb: 0 } }}>
            <Card>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    ></TabList>
                  </Box>
                  <TabPanel value="1">
                    {" "}
                    <LeadFollowUp />
                  </TabPanel>
                  <TabPanel value="2">
                    <LeadQuotation />
                  </TabPanel>
                  <TabPanel value="3">
                    {" "}
                    <LeadContract />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default View;
