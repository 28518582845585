import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getquotationConvertChartDataAsync } from "./quotationConversion.async";

const initialState = {
  quotationConvertChartDataLoader: false,
  quotationConvertChartDataInfo: {}
};

export const quotationConversionSlice = createSlice({
  name: "quotationConversion",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getquotationConvertChartDataAsync.pending),
      (state) => {
        state.quotationConvertChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getquotationConvertChartDataAsync.fulfilled),
      (state, action) => {
        state.quotationConvertChartDataLoader = false;
        state.quotationConvertChartDataInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getquotationConvertChartDataAsync.rejected),
      (state, action) => {
        state.quotationConvertChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptyMis: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyMis } = quotationConversionSlice.actions;
export default quotationConversionSlice.reducer;
