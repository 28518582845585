import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllActivitiesAsync,
  createActivitiesAsync,
  getActivitiesByIdAsync,
  updateActivitiesAsync,
  deleteActivitiesAsync,
} from "./activities.async";

const initialState = {
  activitiesCreateLoader: false,
  activitiesCreateInfo: {},

  activitiesListLoader: false,
  activitiesList: [],
  activitiesListInfo: {},

  activitiesByIdLoading: false,
  activitiesById: {},
  activitiesByIdInfo: {},

  activitiesUpdateLoader: false,
  activitiesUpdateInfo: {},

  activitiesDeleteLoading: false,
  activitiesDeleteInfo: {},
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  extraReducers: (builder) => {
    // Activities Create
    builder.addMatcher(isAnyOf(createActivitiesAsync.pending), (state) => {
      state.activitiesCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createActivitiesAsync.fulfilled),
      (state, action) => {
        state.activitiesCreateLoader = false;
        state.activitiesCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createActivitiesAsync.rejected),
      (state, action) => {
        state.activitiesCreateLoader = false;
      }
    );

    //Activities By ID

    builder.addMatcher(isAnyOf(getActivitiesByIdAsync.pending), (state) => {
      state.activitiesByIdLoading = true;
      state.activitiesByIdInfo = {};
      state.activitiesById = {};
    });
    builder.addMatcher(
      isAnyOf(getActivitiesByIdAsync.fulfilled),
      (state, action) => {
        state.activitiesByIdLoading = false;
        state.activitiesByIdInfo = {};
        state.activitiesById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getActivitiesByIdAsync.rejected),
      (state, action) => {
        state.activitiesByIdLoading = false;
        state.activitiesByIdInfo = {};
        state.activitiesById = {};
      }
    );
    // Activities List
    builder.addMatcher(isAnyOf(getAllActivitiesAsync.pending), (state) => {
      state.activitiesListLoader = true;
      state.activitiesListInfo = {};
      state.activitiesList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllActivitiesAsync.fulfilled),
      (state, action) => {
        state.activitiesListLoader = false;
        state.activitiesListInfo = {};
        state.activitiesList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllActivitiesAsync.rejected),
      (state, action) => {
        state.activitiesListLoader = false;
        state.activitiesListInfo = {};
        state.activitiesList = [];
      }
    );
    //Activities Update
    builder.addMatcher(isAnyOf(updateActivitiesAsync.pending), (state) => {
      state.activitiesUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateActivitiesAsync.fulfilled),
      (state, action) => {
        state.activitiesUpdateLoader = false;
        state.activitiesUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateActivitiesAsync.rejected),
      (state, action) => {
        state.activitiesUpdateLoader = false;
      }
    );
    //Activities Delete
    builder.addMatcher(isAnyOf(deleteActivitiesAsync.pending), (state) => {
      state.activitiesDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteActivitiesAsync.fulfilled),
      (state, action) => {
        state.activitiesDeleteLoading = false;
        state.activitiesDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteActivitiesAsync.rejected),
      (state, action) => {
        state.activitiesDeleteLoading = false;
      }
    );
  },
  reducers: {
    emptyActivities: () => initialState,
  },
});
export const { emptyActivities } = activitiesSlice.actions;

export default activitiesSlice.reducer;
