import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    getAllNotesAsync,
    createNotesAsync
} from "./notes.async";

const initialState = {

  notesListLoader: false,
  notesList: [],
  notesListInfo: {},


  notesCreateLoader: false,
  notesCreateInfo: {},
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  extraReducers: (builder) => {

     // Notes Create
     builder.addMatcher(isAnyOf(createNotesAsync.pending), (state) => {
        state.notesCreateLoader = true;
      });
      builder.addMatcher(isAnyOf(createNotesAsync.fulfilled), (state, action) => {
        state.notesCreateLoader = false;
        state.notesCreateInfo = action.payload;
      });
      builder.addMatcher(isAnyOf(createNotesAsync.rejected), (state, action) => {
        state.notesCreateLoader = false;
      });
   
    // Notes List
    builder.addMatcher(isAnyOf(getAllNotesAsync.pending), (state) => {
      state.notesListLoader = true;
      state.notesListInfo = {};
      state.notesList = [];
    });
    builder.addMatcher(isAnyOf(getAllNotesAsync.fulfilled), (state, action) => {
      state.notesListLoader = false;
      state.notesListInfo = {};
      state.notesList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllNotesAsync.rejected), (state, action) => {
      state.notesListLoader = false;
      state.notesListInfo = {};
      state.notesList = [];
    });
   

   
  },
  reducers: {
    emptyNotes: () => initialState
  }
});
export const { emptyNotes } = notesSlice.actions;

export default notesSlice.reducer;
