import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  generateEWayBill,
  cancelEWayBill,
  generateEInvoice,
  cancelEInvoice,
} from "./gst.async";

const initialState = {
  gstGenerateEWayBillLoader: false,
  gstGenerateEWayBillInfo: {},

  gstCancelEWayBillLoader: false,
  gstCancelEWayBillInfo: {},
  
  gstGenerateEInvoiceLoader: false,
  gstGenerateEInvoiceInfo: {},

  gstCancelEInvoiceLoader: false,
  gstCancelEInvoiceInfo: {},

};

export const gstSlice = createSlice({
  name: "gst",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(generateEWayBill.pending), (state) => {
      state.gstGenerateEWayBillLoader = true;
    });
    builder.addMatcher(isAnyOf(generateEWayBill.fulfilled), (state, action) => {
      state.gstGenerateEWayBillLoader = false;
      state.gstGenerateEWayBillInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(generateEWayBill.rejected), (state, action) => {
      state.gstGenerateEWayBillLoader = false;
    });

    builder.addMatcher(isAnyOf(generateEInvoice.pending), (state) => {
      state.gstGenerateEInvoiceLoader = true;
    });
    builder.addMatcher(isAnyOf(generateEInvoice.fulfilled), (state, action) => {
      state.gstGenerateEInvoiceLoader = false;
      state.gstGenerateEInvoiceInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(generateEInvoice.rejected), (state, action) => {
      state.gstGenerateEInvoiceLoader = false;
    });
  },
  reducers: {
    emptySales: () => initialState,
  },
});
export const { emptySales } = gstSlice.actions;

export default gstSlice.reducer;
