import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllUserAsync,
  getAllUserWithoutPaginationAsync,
  getUserByIdAsync,
  updateUserAsync,
  deleteUserDetailsAsync,
  createUserAsync
} from "./user.async";

const initialState = {
  userLoader: false,
  users: [],
  userById: {},
  userCreateLoader: false,
  userCreateInfo: {},
  userUpdateLoader:false,
  userUpdateInfoLoader: false,
  userUpdateInfo: {},

  UserWithoutPaginationLoader: false,
  UserWithoutPaginationList: {},



};

export const userSlice = createSlice({
  name: "Staff",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllUserAsync.pending,
        getUserByIdAsync.pending,
        getAllUserWithoutPaginationAsync.pending,
        deleteUserDetailsAsync.pending,
        
      ),
      (state) => {
        state.userLoader = true;
        state.userById={}
      }
    );
    builder.addMatcher(
      isAnyOf(getAllUserAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.users = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllUserWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.UserWithoutPaginationList = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteUserDetailsAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.users = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getUserByIdAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.userById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateUserAsync.pending),
      (state) => {
        state.userUpdateLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(updateUserAsync.fulfilled),
      (state, action) => {
        state.userUpdateLoader = false;
        state.userUpdateInfo = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(createUserAsync.pending), (state) => {
      state.userLoader = true;
    });
  
    builder.addMatcher(isAnyOf(createUserAsync.fulfilled), (state, action) => {
      state.userLoader = false;
      state.userCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createUserAsync.rejected), (state, action) => {
      state.userLoader = false;
    });




    builder.addMatcher(
      isAnyOf(
        getAllUserAsync.rejected,
        getAllUserWithoutPaginationAsync.rejected,
        getUserByIdAsync.rejected,
        updateUserAsync.rejected,
        deleteUserDetailsAsync.rejected,
      ),
      (state, action) => {
        state.userLoader = false;
        state.userUpdateLoader = false;
      }
    );
  },

  reducers: {
    emptyUser: () => initialState,
  },
});

export const { emptyUser } = userSlice.actions;

export default userSlice.reducer;
