import { createAsyncThunk } from "@reduxjs/toolkit";
import View from "pages/LeadView/View";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

//GET ALL JOBS
export const getAllJobsAsync = createAsyncThunk(
  "master/getAllJobsAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/list-jobs?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&status=${
        payload.status || ""
      }&customer=${payload.customer || ""}&site=${payload.site || ""}&service=${
        payload.service || ""
      }&quotation=${payload.quotation || ""}&fromDate=${
        payload.startDate || ""
      }&toDate=${payload.endDate || ""}&vesselName=${
        payload.vesselName || ""
      }&containerNo=${payload.containerNo || ""}&serviceType=${payload.serviceType || ""}&location=${payload.location || ""}&lob=${payload.lob || ""}`,
      [],
      toolkit
    );
  }
);

//GET ALL SERVICES
export const getAllServicesAsync = createAsyncThunk(
  "master/getAllServicesAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/service/list-all-services`, [], toolkit);
  }
);

//GET ALL SERVICES
export const getCurrencyAsync = createAsyncThunk(
  "master/getCurrencyAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-currency`, [], toolkit);
  }
);

//GET JOB BY ID
export const getJobByIdAsync = createAsyncThunk(
  "master/getJobByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/get-job/${payload}`,
      [],
      toolkit
    );
  }
);

//GET JOB BY ID
export const getCommonSiteById = createAsyncThunk(
  "master/getCommonSiteById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/list-sites/${payload}`,
      [],
      toolkit
    );
  }
);

//Update JOB  BY ID

export const updateJobAsync = createAsyncThunk(
  "admin/updateJobAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/job/update-job/${payload?.jobInfoId}`,
      payload,
      toolkit
    );
  }
);

//List Item Covered  BY ID

export const listItemCovered = createAsyncThunk(
  "admin/listItemCovered",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/list-itemcovered/${payload}`,
      payload,
      toolkit
    );
  }
);

//Update JOB Status
export const updateJobStatusAsync = createAsyncThunk(
  "admin/updateJobStatusAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/job/update-job-status/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

// CREATE JOB
export const createJobsAsync = createAsyncThunk(
  "master/createJobsAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/service/job/create-job`,
      payload,
      toolkit
    );
  }
);

// getAllJobsWithoutPaginationAsync

export const getAllJobsWithoutPaginationAsync = createAsyncThunk(
  "master/getAllJobsWithoutPaginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/service/job/list-jobs`, [], toolkit);
  }
);

// Get all Ports
export const getAllPortAsyncForJob = createAsyncThunk(
  "master/getAllPortAsyncForJob",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-ports`, [], toolkit);
  }
);

//download Contract Csv File
export const JobsExcelDownloadAsync = ({ page, limit, status, customer, service, site, quotation, startDate, endDate, vesselName, containerNo, search}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/service/job/jobs-export-to-csv?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page || 1}&limit=${
      limit || 10
    }&search=${search || ""}&status=${
      status || ""
    }&customer=${customer || ""}&site=${site || ""}&service=${
      service || ""
    }&quotation=${quotation || ""}&fromDate=${
      startDate || ""
    }&toDate=${endDate || ""}&vesselName=${
      vesselName || ""
    }&containerNo=${containerNo || ""}`,
    "_parent"
  );
};

// Job Activity View
export const getActivityViewByJobIdAsync = createAsyncThunk(
  "job/ActivityViewByJobId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/roster/get-activity-log?jobId=${payload?.jobaActivityInfoId}&page=${
        payload?.page || 1
      }&limit=${payload?.limit || 10}`,
      [],
      toolkit
    );
  }
);

//GET Accreditation By Service id
export const getAccreditationByServiceIdAsync = createAsyncThunk(
  "master/getAccreditationByServiceId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/get-accreditation-for-job/${payload?.serviceId}/${payload?.jobId}`,
      [],
      toolkit
    );
  }
);

//GET Job By Customer id
export const getJobByCustomerIdAsync = createAsyncThunk(
  "master/getJobByCustomerId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/list-jobs?customer=${payload?.customer || ""}`,
      [],
      toolkit
    );
  }
);

//Job Delete
export const deleteJobAsync = createAsyncThunk(
  "master/deleteJobAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/service/job/delete-single-job/${payload}`,
      [],
      toolkit
    );
  }
);

// get address by service id
export const getAddressByServiceIdAsync = createAsyncThunk(
  "master/getAddressByServiceId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/job/get-address/${payload}`,
      [],
      toolkit
    );
  }
);
