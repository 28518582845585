import React, { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import Iconify from "components/iconify/Iconify";
import CustomTable from "components/CustomTable/CustomTable";
import FollowUp from "./FollowUp";
import moment from "moment";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllFollowUpAsync } from "redux/slices/followup/followup.async";

const LeadFollowUp = () => {
  const [getFollowupId, setFollowupId] = useState("");
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(10);

  const { followUpListLoader, followUpList = [] } = useSelector(
    (state) => state?.followUp
  );

  const leadFollowupId = (data) => {
    setFollowupId(data);
  };

  useEffect(() => {
    if (getFollowupId) {
      const payload = {
        page: pageNumber,
        limit: perPageNumber,
        leadId: getFollowupId,
      };
      dispatch(getAllFollowUpAsync(payload));
    }
  }, [pageNumber, perPageNumber, getFollowupId]);

  const handlePerRowsChange = (perPageNo, page) => {
    setPageNumber(page);
    setPerPageNumber(perPageNo);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) =>
        pageNumber === 1
          ? index + 1
          : index === 9
          ? `${pageNumber}0`
          : `${pageNumber - 1}${index + 1}`,
      width: "80px",
    },

    {
      name: "Type ",
      selector: (row) => capitalize(row.type),
    },
    {
      name: " Response",
      selector: (row) => row.followupResponse,
      width: "200px",
    },
    {
      name: "Follow up date",
      selector: (row) =>
        moment(row?.nextFollowupDate.split("T")[0]).format("DD MMM YYYY"),
      width: "200px",
    },

    {
      name: "Next Follow Up.",
      selector: (row) => {
        const getTime = new Date(row?.nextFollowupTime);

        const year = getTime.getUTCFullYear();
        const month = ("0" + (getTime.getUTCMonth() + 1)).slice(-2);
        const date = ("0" + getTime.getUTCDate()).slice(-2);
        const hour = getTime.getUTCHours() % 12 || 12; // convert to 12-hour format
        const minute = ("0" + getTime.getUTCMinutes()).slice(-2);
        const period = getTime.getUTCHours() < 12 ? "AM" : "PM"; // get AM/PM

        return `${date}-${month}-${year} ${hour}:${minute} ${period}`;
      },
      width: "200px",
    },

    {
      name: "Assigned To",
      selector: (row) =>
        `${row.assignTo?.firstName ?? "N/A"} ${row.assignTo?.lastName ?? ""}`,
      width: "180px",
    },
  ];

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={{ marginTop: "-50px" }}>
        <FollowUp leadFollowupId={leadFollowupId} />
        <CustomTable
          columns={columns}
          loader={followUpListLoader}
          data={followUpList?.data}
          totalcount={followUpList?.pagination?.totalItems}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default LeadFollowUp;
