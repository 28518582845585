import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllCustomerAsync,
  deleteCustomerAsync,
  getCustomerWithoutPaginationAsync,
  getAllCustomerWithoutPaginationAsync,
  getAllLocationsByIdAsync,
} from "./customer.async";

const initialState = {
  customerListLoader: false,
  customerList: [],
  customerListInfo: {},

  customerDeleteLoading: false,
  customerDeleteInfo: {},

  customerWithoutPaginationLoader: false,
  customerWithoutPaginationList: [],
  customerWithoutPaginationInfo: {},

  customerListWithoutPaginationLoader: false,
  customerListWithoutPagination: [],
  customerListInfoWithoutPagination: {},

  customerByIdLoading: false,
  customerByIdInfo: {},
  customerById: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: (builder) => {
    //Customer List
    builder.addMatcher(isAnyOf(getAllCustomerAsync.pending), (state) => {
      state.customerListLoader = true;
      state.customerListInfo = {};
      state.customerList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllCustomerAsync.fulfilled),
      (state, action) => {
        state.customerListLoader = false;
        state.customerListInfo = {};
        state.customerList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCustomerAsync.rejected),
      (state, action) => {
        state.customerListLoader = false;
        state.customerListInfo = {};
        state.customerList = [];
      }
    );

    //Customer Delete
    builder.addMatcher(isAnyOf(deleteCustomerAsync.pending), (state) => {
      state.customerDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteCustomerAsync.fulfilled),
      (state, action) => {
        state.customerDeleteLoading = false;
        state.customerDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteCustomerAsync.rejected),
      (state, action) => {
        state.customerDeleteLoading = false;
      }
    );

    //
    builder.addMatcher(
      isAnyOf(getCustomerWithoutPaginationAsync.pending),
      (state) => {
        state.customerWithoutPaginationLoader = true;
        state.customerWithoutPaginationInfo = {};
        state.customerWithoutPaginationList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCustomerWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.customerWithoutPaginationLoader = false;
        state.customerWithoutPaginationInfo = {};
        state.customerWithoutPaginationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCustomerWithoutPaginationAsync.rejected),
      (state, action) => {
        state.customerWithoutPaginationLoader = false;
        state.customerWithoutPaginationInfo = {};
        state.customerWithoutPaginationList = [];
      }
    );

    //Get All Customer Without Pagination
    builder.addMatcher(
      isAnyOf(getAllCustomerWithoutPaginationAsync.pending),
      (state) => {
        state.customerListWithoutPaginationLoader = true;
        state.customerListInfoWithoutPagination = {};
        state.customerListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCustomerWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.customerListWithoutPaginationLoader = false;
        state.customerListInfoWithoutPagination = {};
        state.customerListWithoutPagination = action?.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCustomerWithoutPaginationAsync.rejected),
      (state, action) => {
        state.customerListWithoutPaginationLoader = false;
        state.customerListInfoWithoutPagination = {};
        state.customerListWithoutPagination = [];
      }
    );

    //Customer Get location By Id
    builder.addMatcher(isAnyOf(getAllLocationsByIdAsync.pending), (state) => {
      state.customerByIdLoading = true;
      state.customerByIdInfo = {};
      state.customerById = {};
    });
    builder.addMatcher(
      isAnyOf(getAllLocationsByIdAsync.fulfilled),
      (state, action) => {
        state.customerByIdLoading = false;
        state.customerByIdInfo = {};
        state.customerById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllLocationsByIdAsync.rejected),
      (state, action) => {
        state.customerByIdLoading = false;
        state.customerByIdInfo = {};
        state.customerById = {};
      }
    );
  },
  reducers: {
    emptyCustomer: () => initialState,
  },
});
export const { emptyCustomer } = customerSlice.actions;

export default customerSlice.reducer;
