import { React, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import CustomTable from 'components/CustomTable/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { EntityContainerColumn } from './EntityContainerColumn';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    width: '600px',
  },
  '& .MuiDialogTitle-root': {
    width: '600px',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ContainerModal({ open, setOpen, entity, row }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(10);

  const { certificateListLoader = [] } = useSelector(
    (state) => state?.certificate
  );
  const handlePerRowsChange = (perPageNo, page) => {
    setPageNumber(page);
    setPerPageNumber(perPageNo);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };
  return (
    <div xs={{ width: '798px !important' }}>
      <Button variant='outlined' onClick={open}>
        Open dialog
      </Button>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClick={() => setOpen(false)}
        >
          Container Number
          <Divider />
          <IconButton
            aria-label='close'
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </BootstrapDialogTitle>
        <DialogContent>
          <CustomTable
            // Required later
            // data={ entity ?  (entity?.csvData ?? entity[0]?.csvData ) : {} }
            data={entity ? entity[0] : []}
            columns={EntityContainerColumn({
              open,
              setOpen,
              pageNumber,
            })}
            pagination={false}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
