import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllSitesAsync,
  createSitesAsync,
  getSitesByIdAsync,
  updateSitesAsync,
  deleteSitesAsync,
  getAllPinCodeAsync,
  getAllCountryAsync,
  getAllLOBAsync,
  getAllSitesWithoutPaginationAsync,
  getSiteByRoleAsync
} from "./sites.async";

const initialState = {
  siteListLoader: false,
  siteList: [],
  siteListInfo: {},

  siteListWithoutPaginationLoader: false,
  siteListWithoutPagination: [],
  siteListWithoutPaginationInfo: {},

  siteCreateLoader: false,
  siteCreateInfo: {},

  siteDeleteLoading: false,
  siteDeleteInfo: {},

  siteByIdLoading: false,
  siteById: {},
  siteByIdInfo: {},

  siteUpdateLoader: false,
  siteUpdateInfo: {},

  pinCodeLoader: false,
  getPinCodeList: [],
  getPinCodeInfo: {},

  countryLoader : false ,
  countryList : [],
  countryListInfo : {},

  lobLoader : false ,
  lobList : [],
  lobListInfo : {},

  siteByRoleLoader: false,
  siteByRoleList: [],
  siteByRoleInfo: {}
};

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  extraReducers: (builder) => {

     // sites List without pagination
     builder.addMatcher(isAnyOf(getAllSitesWithoutPaginationAsync.pending), (state) => {
      state.siteListWithoutPaginationLoader = true;
    });

    builder.addMatcher(isAnyOf(getAllSitesWithoutPaginationAsync.fulfilled), (state, action) => {
      state.siteListWithoutPaginationLoader = false;
      state.siteListWithoutPaginationInfo = {};
      state.siteListWithoutPagination = action.payload?.data || [];
    });

    builder.addMatcher(isAnyOf(getAllSitesWithoutPaginationAsync.rejected), (state, action) => {
      state.siteListWithoutPaginationLoader = false;
      state.siteListWithoutPaginationInfo = {};
      state.siteListWithoutPagination = [];
    });
    // sites List
    builder.addMatcher(isAnyOf(getAllSitesAsync.pending), (state) => {
      state.siteListLoader = true;
    });

    builder.addMatcher(isAnyOf(getAllSitesAsync.fulfilled), (state, action) => {
      state.siteListLoader = false;
      state.siteListInfo = {};
      state.siteList = action.payload?.data || [];
    });

    builder.addMatcher(isAnyOf(getAllSitesAsync.rejected), (state, action) => {
      state.siteListLoader = false;
      state.siteListInfo = {};
      state.siteList = [];
    });

    //sites create

    builder.addMatcher(isAnyOf(createSitesAsync.pending), (state) => {
      state.siteCreateLoader = true;
    });

    builder.addMatcher(isAnyOf(createSitesAsync.fulfilled), (state, action) => {
      state.siteCreateLoader = false;
      state.siteCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createSitesAsync.rejected), (state, action) => {
      state.siteCreateLoader = false;
    });

    //Sites Delete
    builder.addMatcher(isAnyOf(deleteSitesAsync.pending), (state) => {
      state.siteDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteSitesAsync.fulfilled), (state, action) => {
      state.siteDeleteLoading = false;
      state.siteDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteSitesAsync.rejected), (state, action) => {
      state.siteDeleteLoading = false;
    });

    //Site By Id
    builder.addMatcher(isAnyOf(getSitesByIdAsync.pending), (state) => {
      state.siteByIdLoading = true;
      state.siteByIdInfo = {};
      state.siteById = {};
    });

    builder.addMatcher(
      isAnyOf(getSitesByIdAsync.fulfilled),
      (state, action) => {
        state.siteByIdLoading = false;
        state.siteByIdInfo = {};
        state.siteById = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getSitesByIdAsync.rejected), (state, action) => {
      state.siteByIdLoading = false;
      state.siteByIdInfo = {};
      state.siteById = {};
    });

    //Sites Update
    builder.addMatcher(isAnyOf(updateSitesAsync.pending), (state) => {
      state.siteUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateSitesAsync.fulfilled), (state, action) => {
      state.siteUpdateLoader = false;
      state.siteUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateSitesAsync.rejected), (state, action) => {
      state.siteUpdateLoader = false;
    });

    //Pin code Info
    builder.addMatcher(isAnyOf(getAllPinCodeAsync.pending), (state) => {
      state.pinCodeLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllPinCodeAsync.fulfilled),
      (state, action) => {
        state.pinCodeLoader = false;
        state.getPinCodeInfo = {};
        state.getPinCodeList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPinCodeAsync.rejected),
      (state, action) => {
        state.pinCodeLoader = false;
      }
    );

    // country info
    builder.addMatcher(isAnyOf(getAllCountryAsync.pending), (state) => {
      state.countryLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllCountryAsync.fulfilled),
      (state, action) => {
        state.countryLoader = false;
        state.countryListInfo = {};
        state.countryList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCountryAsync.rejected),
      (state, action) => {
        state.countryLoader = false;
      }
    );

    // LOB info
    builder.addMatcher(isAnyOf(getAllLOBAsync.pending), (state) => {
      state.countryLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllLOBAsync.fulfilled),
      (state, action) => {
        state.lobLoader = false;
        state.lobListInfo = {};
        state.lobList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllLOBAsync.rejected),
      (state, action) => {
        state.countryLoader = false;
      }
    );

     // sites by role
     builder.addMatcher(isAnyOf(getSiteByRoleAsync.pending), (state) => {
      state.siteByRoleLoader = true;
    });

    builder.addMatcher(isAnyOf(getSiteByRoleAsync.fulfilled), (state, action) => {
      state.siteByRoleLoader = false;
      state.siteByRoleInfo = {};
      state.siteByRoleList = action.payload?.data || [];
    });

    builder.addMatcher(isAnyOf(getSiteByRoleAsync.rejected), (state, action) => {
      state.siteByRoleLoader = false;
      state.siteByRoleInfo = {};
      state.siteByRoleList = [];
    });
  },
  reducers: {
    emptySites: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptySites } = sitesSlice.actions;
export default sitesSlice.reducer;
